

import {Text,Image,View,CheckBox} from "react-native";
import SpesificOrder from '../SpesificOrder';
import { db,auth,user } from '../../firebase';
import React, { useState } from 'react';
import './pagescss/signup.css';
import StepChart from '../StepsChart';
import { Button } from "../Button";
import MaskInput from 'react-maskinput';
import { Link } from 'react-router-dom';
import '../../languages/i18n'; 
import {useTranslation} from 'react-i18next';

function MessageViewer() {
    const {t, i18n} = useTranslation();
 
    const search = window.location.search; // returns the URL query String
    const params = new URLSearchParams(search); 
    const IdFromURL = params.get('text'); 

  return (
        <div className="body">
                <View style={styles.container2}>
                    <h1 >{IdFromURL}</h1>
                    <br/>
                        <View style={styles.buttoncontainer} >
                                <Text>{t(IdFromURL)}</Text>
                        </View>
                </View>
        </div>
  );
}

const styles = {
    container2: {
      marginTop: 10,
      flexDirection: "column",
      textAlign: 'center',
      flex: 1,
    },
    buttoncontainer: {
        marginRight: "10%",
        marginLeft: "10%",
        textAlign: 'left',
        flexDirection: "column",
        marginTop: 50,
        flex:1,

    },
  }

export default MessageViewer;

