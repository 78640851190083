import React from "react";
import "../../App.css";
import Whyervesta from "../Whyervesta";
import StepsChart from "../StepsChart";
import HowItWorkscomp from "../HowItWorkscomp";
import Commissions from "../Commissions";
import Details from "../Details";
import Footer from "../Footer";
function HowItWorks() {
 
    

  return (
    <>
      <Whyervesta/>
      <HowItWorkscomp/>
      <StepsChart/>
      <Commissions/>
      <Details/>
      <div style={{padding:"50px",textAlign:"center"}}>
         <text className="form__logotext" translate="no" to="/" >ERVESTA</text><br/>
         <text className="form__logotextslogan" translate="no" to="/"  >shop smart</text>
       </div>
       <Footer/>
    </>
  );
}

export default HowItWorks;