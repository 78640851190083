import React, {useState, useEffect} from "react";
import {Image} from "react-native";
import {Button} from "./Button";
import "./HeroSection.css";
import SlidingBrands from "./SlidingBrands";
import '../languages/i18n'; 
import {useTranslation} from 'react-i18next';
import ReactGA from 'react-ga';

function HeroSection() {

  const {t, i18n} = useTranslation();

  const [showvideo, setshowvideo] = useState(true);
  const changeshowvideo = () => {
    if(window.innerWidth <= 960){
        setshowvideo(false);
    }else {
        setshowvideo(true);
    }
  };

  useEffect(() => changeshowvideo(),[]);
  window.addEventListener("resize", changeshowvideo);

  ReactGA.initialize('G-6EFTZ9KYRH');
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
  <view className="hero-containertop">
    {showvideo && <video src="/videos/video-4.mp4" autoPlay loop muted />}
    <div className="hero-container">
        <h1>{t("buyproductsfromturkey")}</h1>
        <p> {t("weshipproducts")}</p>
        <div className="hero-btns">
            <Button onClick={()=>window.location.href = "./services"}
                className="btns"
                buttonStyle="btn--outline2"
                buttonSize="btn--large2"
                >
                    <i class="fa-solid fa-cart-shopping"></i>&nbsp;
                    {t("placeanorder")}
            </Button>
        </div>
    </div>
            {!showvideo && <Image style={{marginTop:-600,zIndex:-1, width:440, height:600, textAlign: "center"}} source="/images/ervestaimgmain.jpg" />}
    
    </view>
  )
}
export default HeroSection;