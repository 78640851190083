

import {Text,Image,View,CheckBox} from "react-native";
import SpesificOrder from './SpesificOrder';
import { db,auth,user } from '../firebase';
import React, { useState } from 'react';
import './pages/pagescss/signup.css';
import MaskInput from 'react-maskinput';
import { Link } from 'react-router-dom';

function PostOrder() {
 
    const search = window.location.search; // returns the URL query String
    const params = new URLSearchParams(search); 
    const IdFromURL = params.get('uid'); 
    
    const [info , setInfo] = useState([]);
    const [iseditmode , setiseditmode] = useState(false);
  
    const [uidtext, setuidtext] = useState("uid");
    const changeuidtext = (text) => setuidtext(text);

    const [isSelected, setSelection] = useState(true);

    const [productlink, setproductlink] = useState("productlink");
    const [productname, setproductname] = useState("productname");
    const [productcode, setproductcode] = useState("productcode");
    const [productcolor, setproductcolor] = useState("productcolor");
    const [productpriceintl, setproductpriceintl] = useState("productpriceintl");
    const [productquantity, setproductquantity] = useState("productquantity");
    const [productsize, setproductsize] = useState("productsize");
    const [producttotalcost, setproducttotalcost] = useState("producttotalcost");
    const [productervestafee, setproductervestafee] = useState("productervestafee");
    const [productcomment, setproductcomment] = useState("productcomment");
    const [status, setstatus] = useState("status");
    const [useruid, setuseruid] = useState("useruid");
    const [usermail, setusermail] = useState("usermail");
    const [date, setdate] = useState("date");
    const [adresscity, setadresscity] = useState("adresscity");
    const [adresscountry, setadresscountry] = useState("adresscountry");
    const [adresslastname, setadresslastname] = useState("adresslastname");
    const [adressline1, setadressline1] = useState("adressline1");
    const [adressline2, setadressline2] = useState("");
    const [adressname, setadressname] = useState("adressname");
    const [adressphonenumber, setadressphonenumber] = useState("adressphonenumber");
    const [adresspostcode, setadresspostcode] = useState("adresspostcode");

    const [ordernumber, setordernumber] = useState("ordernumber");
      
    const handleInputTextChange = () =>{
                 
        document.getElementById("productervestafee").placeholder = (parseInt(document.getElementById("productquantity").value)*parseInt(document.getElementById("productpriceintl").value)*0.2*30).toFixed(0)+" KZT";
        document.getElementById("producttotalcost").placeholder=
        parseInt(document.getElementById("productquantity").value)*parseInt(document.getElementById("productpriceintl").value)*30
        +parseInt(document.getElementById("productervestafee").placeholder) +" KZT";
        
      
    };

    auth.onAuthStateChanged(function(user) {
        if (user) {
        //changesignstatus(true);
        changeuidtext(user.uid)
        changesignstatus(true);
        //changeshowlogin(false);

            db.ref("users/"+user.uid+"/orders/"+IdFromURL).once("value").then((querySnapshot) => {
                setordernumber(IdFromURL);
                setproductlink(querySnapshot.child("productlink").val());
                setproductname(querySnapshot.child("productname").val());
                setproductcode(querySnapshot.child("productcode").val());
                setproductcolor(querySnapshot.child("productcolor").val());
                setproductpriceintl(querySnapshot.child("productpriceintl").val());
                setproductquantity(querySnapshot.child("productquantity").val());
                setproductsize(querySnapshot.child("productsize").val());
                setproducttotalcost(querySnapshot.child("producttotalcost").val());
                setproductervestafee(querySnapshot.child("productervestafee").val());
                setproductcomment(querySnapshot.child("productcomment").val()); 
                setstatus(querySnapshot.child("status").val());
                setuseruid(querySnapshot.child("useruid").val());
                setusermail(querySnapshot.child("usermail").val());
                setdate(querySnapshot.child("date").val());
                
                setadresscity(querySnapshot.child("adresscity").val());
                setadresscountry(querySnapshot.child("adresscountry").val());
                setadresslastname(querySnapshot.child("adresslastname").val());
                setadressline1(querySnapshot.child("adressline1").val());
                setadressline2(querySnapshot.child("adressline2").val());
                setadressname(querySnapshot.child("adressname").val());
                setadressphonenumber(querySnapshot.child("adressphonenumber").val());
                setadresspostcode(querySnapshot.child("adresspostcode").val());

                document.getElementById("productlink").value = querySnapshot.child("productlink").val();
                document.getElementById("productname").value = querySnapshot.child("productname").val();
                document.getElementById("productcode").value = querySnapshot.child("productcode").val();
                document.getElementById("productsize").value = querySnapshot.child("productsize").val();
                document.getElementById("productcolor").value = querySnapshot.child("productcolor").val();
                document.getElementById("productquantity").value = querySnapshot.child("productquantity").val();
                document.getElementById("productpriceintl").value = querySnapshot.child("productpriceintl").val();
                document.getElementById("productervestafee").placeholder = querySnapshot.child("productervestafee").val();
                document.getElementById("producttotalcost").placeholder = querySnapshot.child("producttotalcost").val();
                document.getElementById("productcomment").value = querySnapshot.child("productcomment").val();
                
                document.getElementById("adressline1").value = querySnapshot.child("adressline1").val();
                document.getElementById("adressline2").value = querySnapshot.child("adressline2").val();
                document.getElementById("adresscity").value = querySnapshot.child("adresscity").val();
                document.getElementById("adresspostcode").value = querySnapshot.child("adresspostcode").val();
                document.getElementById("adresscountry").placeholder = querySnapshot.child("adresscountry").val();
                document.getElementById("adressphonenumber").value = querySnapshot.child("adressphonenumber").val();
                document.getElementById("adressname").value = querySnapshot.child("adressname").val();
                document.getElementById("adresslastname").value = querySnapshot.child("adresslastname").val();

            })
        }else {
            
        }
    });
    
    function getDate(){
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
    
        today = mm + '/' + dd + '/' + yyyy;
        return today;
    }
    function validateproductinputs() {
      var outputmsg = "true";
      if(document.getElementById("topic").value.length<2){
        outputmsg = "Topic is too short.\n"
        document.getElementById("topic").style.border="2px solid red";
      }    
      if(document.getElementById("topic").value.length>1000){
        outputmsg = "Topic is too long. Maximum 1000 char allowed.\n"
        document.getElementById("topic").style.border="2px solid red";
      } 
      if(document.getElementById("message").value.length<2){
        outputmsg = "Message is too short.\n"
        document.getElementById("message").style.border="2px solid red";
      } 
      if(document.getElementById("message").value.length>2000){
        outputmsg = "Message is too long. Maximum 2000 char allowed.\n"
        document.getElementById("message").style.border="2px solid red";
      } 
      alert(outputmsg);
      if(outputmsg == "true")
      return true;
      else {
        setDisable(false);
        return false;}
    }
    const [signedin, setSignedIn] = useState(false);
    const changesignstatus = (mybool) => setSignedIn(mybool);
    const [isdisabled, setDisable] = useState(false);
    const gethelp = () =>{

        setDisable(true);
        if(signedin){
                  if(validateproductinputs()){
          db.ref("/gethelp/"+IdFromURL).set({
              topic: document.getElementById("topic").value,
              message: document.getElementById("message").value,
            })
            .then(() => {
              setDisable(false);
                            window.location.href = "./profile";
            })}
          }else{
              alert("You are not signed in.");
          }
      
      };

  return (
    <View style={styles.container1}>
        
      <View style={styles.container2}>
            <h1 > Get help for your order: {IdFromURL}</h1>
            
              
              <text className="form__titellabel" for="firstName">Your Mesage</text><br/>
              <Text className="" >Please explain clearly what do you need helf for?</Text>
              
                  
                  <div >
                    <Text className="" >Topic*</Text><br/>
                    <input  type="text" id="topic"  className="form__halfinput" placeholder="What is it about?"/>
                  </div>

              
              
          <div >
                    <Text className="" >Your message*</Text><br/>
                    <textarea cols="40" rows="5"  className="form__halfinput"  style={{height:"150px"}} type="text"  id="message" placeholder="Your message"/>
                  </div>
          <View >
              <button disabled={isdisabled} className= "btn--disabled" onClick={gethelp} style={styles.buttoncontainer} >SEND MESSAGE</button> 

          </View>
            
              


      </View>
    </View>
  );
}

const styles = {
    container1: {
      marginTop: 55,
      flexDirection: "row",
      textAlign: 'center',
      flex: 1,
    },
    container2: {
      marginTop: 10,
      flexDirection: "column",
      textAlign: 'center',
      flex: 1,
    },
    container: {
      marginTop: 5,
      flexDirection: "row",
      marginBottom: 5,
      flex: 1
    },
    buttoncontainer: {
        marginRight: "30%",
        marginLeft: "30%",
        textAlign: 'center',
        marginTop: 5,
    },
    smallerbuttoncontainer: {
        marginRight: "35%",
        marginLeft: "35%",
        textAlign: 'center',
        marginTop: 5,
        marginBottom:20
    },
    imagecontainer: {
        width: 120,
        height: 120,
        textAlign: 'center',
        marginBottom: 5,
    },
    rowcontainer2: {
        width: "100%",
        textAlign: 'center',
        flexDirection: "row",
        justifyContent: 'center',
        flex:1,
    },
    infobarcontainer: {
      textAlign: 'left',
      marginBottom: 5,
      flex:5,
    },
    statuscontainer: {
      textAlign: 'center',
      marginBottom: 0,
      marginLeft: 5,
      flex:1.2,
    },
    actionscontainer: {
      textAlign: 'center',
      marginBottom: 5,
      marginLeft: 5,
      flex:1.2,
      justifyContent: 'space-between',
    },
    rowcontainer: {width: "95%",
      textAlign: 'left',
      flexDirection: "row",
      marginBottom: 5,
      justifyContent: 'space-between',
      flex:1.2,
    }
  }

export default PostOrder;

