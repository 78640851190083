

import {Text,Image,View,CheckBox} from "react-native";
import SpesificOrder from '../SpesificOrder';
import { db,auth,user } from '../../firebase';
import React, { useState } from 'react';
import './pagescss/signup.css';
import MaskInput from 'react-maskinput';
import { Link } from 'react-router-dom';
import '../../languages/i18n'; 
import {useTranslation} from 'react-i18next';

function PostOrder() {
 
    const search = window.location.search; // returns the URL query String
    const params = new URLSearchParams(search); 
    const IdFromURL = params.get('uid'); 
    
    const [info , setInfo] = useState([]);
    const [iseditmode , setiseditmode] = useState(false);
  
    const [uidtext, setuidtext] = useState("uid");
    const changeuidtext = (text) => setuidtext(text);

    const [isSelected, setSelection] = useState(true);

    const [productlink, setproductlink] = useState("productlink");
    const [productname, setproductname] = useState("productname");
    const [productcode, setproductcode] = useState("productcode");
    const [productcolor, setproductcolor] = useState("productcolor");
    const [productpriceintl, setproductpriceintl] = useState("productpriceintl");
    const [productquantity, setproductquantity] = useState("productquantity");
    const [productsize, setproductsize] = useState("productsize");
    const [producttotalcost, setproducttotalcost] = useState("producttotalcost");
    const [productervestafee, setproductervestafee] = useState("productervestafee");
    const [productcomment, setproductcomment] = useState("productcomment");
    const [status, setstatus] = useState("status");
    const [useruid, setuseruid] = useState("useruid");
    const [usermail, setusermail] = useState("usermail");
    const [date, setdate] = useState("date");
    const [adresscity, setadresscity] = useState("adresscity");
    const [adresscountry, setadresscountry] = useState("adresscountry");
    const [adresslastname, setadresslastname] = useState("adresslastname");
    const [adressline1, setadressline1] = useState("adressline1");
    const [adressline2, setadressline2] = useState("");
    const [adressname, setadressname] = useState("adressname");
    const [adressphonenumber, setadressphonenumber] = useState("adressphonenumber");
    const [adresspostcode, setadresspostcode] = useState("adresspostcode");

    const [ordernumber, setordernumber] = useState("ordernumber");
      
    const handleInputTextChange = () =>{

      document.getElementById("productpriceintl").value = document.getElementById("productpriceintl").value.replaceAll(",",".");
        
      if(document.getElementById("selecter").value === "metal"){
        document.getElementById("productervestafee").placeholder = (parseFloat(document.getElementById("productquantity").value)*parseFloat(document.getElementById("productpriceintl").value)*0.5*trykztrate).toFixed(0)+" KZT";
        if(parseFloat(document.getElementById("productervestafee").placeholder) < parseFloat(document.getElementById("productquantity").value*4500)){
          document.getElementById("productervestafee").placeholder = parseFloat(document.getElementById("productquantity").value*4500)+" KZT";
        }
        document.getElementById("producttotalcost").placeholder=(
        parseFloat(document.getElementById("productquantity").value)*parseFloat(document.getElementById("productpriceintl").value)*trykztrate
        +parseFloat(document.getElementById("productervestafee").placeholder)).toFixed(0)+" KZT";
      }else {
      document.getElementById("productervestafee").placeholder = (parseFloat(document.getElementById("productquantity").value)*parseFloat(document.getElementById("productpriceintl").value)*0.2*trykztrate).toFixed(0)+" KZT";
        if(parseFloat(document.getElementById("productervestafee").placeholder) < parseFloat(document.getElementById("productquantity").value*4500)){
          document.getElementById("productervestafee").placeholder = parseFloat(document.getElementById("productquantity").value*4500)+" KZT";
        }
        document.getElementById("producttotalcost").placeholder=(
        parseFloat(document.getElementById("productquantity").value)*parseFloat(document.getElementById("productpriceintl").value)*trykztrate
        +parseFloat(document.getElementById("productervestafee").placeholder)).toFixed(0)+" KZT";
      }

    };
    const [trykztrate, settrykztrate] = useState(0);
    const {t, i18n} = useTranslation();
    function getTRYKZT(){

      db.ref("/trykzt")
      .once("value")
      .then(snapshot => {
        const d = new Date();
        var day = d.getDay();
        if(parseInt(day) - snapshot.child("lastday").val()>1||parseInt(day) - snapshot.child("lastday").val()<-1){
            
          fetch('https://v6.exchangerate-api.com/v6/5ad59fb5257e4df5b9ceefbb/pair/TRY/KZT').then((response) => response.json()).then((json) => {
              settrykztrate(parseFloat(json.conversion_rate).toFixed(2));
              db.ref("/trykzt/").set({
                ratio: parseFloat(json.conversion_rate).toFixed(2),
                lastday: day,
                  })
                  .then(() => {
                      return parseFloat(json.conversion_rate).toFixed(2);
                    })
              }).catch((error) => {
                  console.error(error);
              });
            }
        settrykztrate(parseFloat(snapshot.child("ratio").val()).toFixed(2));
        });
    }
    
    getTRYKZT();
    auth.onAuthStateChanged(function(user) {
        if (user) {
        //changesignstatus(true);
        changeuidtext(user.uid)
        changesignstatus(true);
        //changeshowlogin(false);

            db.ref("users/"+user.uid+"/orders/"+IdFromURL).once("value").then((querySnapshot) => {
                setordernumber(IdFromURL);
                setproductlink(querySnapshot.child("productlink").val());
                setproductname(querySnapshot.child("productname").val());
                setproductcode(querySnapshot.child("productcode").val());
                setproductcolor(querySnapshot.child("productcolor").val());
                setproductpriceintl(querySnapshot.child("productpriceintl").val());
                setproductquantity(querySnapshot.child("productquantity").val());
                setproductsize(querySnapshot.child("productsize").val());
                setproducttotalcost(querySnapshot.child("producttotalcost").val());
                setproductervestafee(querySnapshot.child("productervestafee").val());
                setproductcomment(querySnapshot.child("productcomment").val()); 
                setstatus(querySnapshot.child("status").val());
                setuseruid(querySnapshot.child("useruid").val());
                setusermail(querySnapshot.child("usermail").val());
                setdate(querySnapshot.child("date").val());
                
                setadresscity(querySnapshot.child("adresscity").val());
                setadresscountry(querySnapshot.child("adresscountry").val());
                setadresslastname(querySnapshot.child("adresslastname").val());
                setadressline1(querySnapshot.child("adressline1").val());
                setadressline2(querySnapshot.child("adressline2").val());
                setadressname(querySnapshot.child("adressname").val());
                setadressphonenumber(querySnapshot.child("adressphonenumber").val());
                setadresspostcode(querySnapshot.child("adresspostcode").val());

                document.getElementById("productlink").value = querySnapshot.child("productlink").val();
                document.getElementById("productname").value = querySnapshot.child("productname").val();
                document.getElementById("productcode").value = querySnapshot.child("productcode").val();
                document.getElementById("productsize").value = querySnapshot.child("productsize").val();
                document.getElementById("productcolor").value = querySnapshot.child("productcolor").val();
                document.getElementById("productquantity").value = querySnapshot.child("productquantity").val();
                document.getElementById("productpriceintl").value = querySnapshot.child("productpriceintl").val();
                document.getElementById("productervestafee").placeholder = querySnapshot.child("productervestafee").val();
                document.getElementById("producttotalcost").placeholder = querySnapshot.child("producttotalcost").val();
                document.getElementById("productcomment").value = querySnapshot.child("productcomment").val();
                
                document.getElementById("adressline1").value = querySnapshot.child("adressline1").val();
                document.getElementById("adressline2").value = querySnapshot.child("adressline2").val();
                document.getElementById("adresscity").value = querySnapshot.child("adresscity").val();
                document.getElementById("adresspostcode").value = querySnapshot.child("adresspostcode").val();
                document.getElementById("adresscountry").placeholder = querySnapshot.child("adresscountry").val();
                document.getElementById("adressphonenumber").value = querySnapshot.child("adressphonenumber").val();
                document.getElementById("adressname").value = querySnapshot.child("adressname").val();
                document.getElementById("adresslastname").value = querySnapshot.child("adresslastname").val();
                document.getElementById("selecter").value = querySnapshot.child("material").val();
            })
        }else {
            
        }
    });
    
    function getDate(){
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
    
        today = mm + '/' + dd + '/' + yyyy;
        return today;
    }
    function validateproductinputs() {
      var outputmsg = "";
      if(document.getElementById("productlink").value.length<5){
        outputmsg = t("debug1")
        document.getElementById("productlink").style.border="2px solid red";
      }    
      if(!document.getElementById("productlink").value.includes(".")){
        outputmsg = outputmsg+t("debug2")
        document.getElementById("productlink").style.border="2px solid red";
      } 
      if(document.getElementById("productname").value.length<2){
        outputmsg = outputmsg+t("debug3")
        document.getElementById("productname").style.border="2px solid red";
      }
      if(document.getElementById("productsize").value.length<1){
        outputmsg = outputmsg+t("debug4")
        document.getElementById("productsize").style.border="2px solid red";
      }
      if(document.getElementById("productcolor").value.length<1){
        outputmsg = outputmsg+t("debug5")
        document.getElementById("productcolor").style.border="2px solid red";
      }
      if(document.getElementById("productpriceintl").value.length<1){
        outputmsg = outputmsg+t("debug6")
        document.getElementById("productpriceintl").style.border="2px solid red";
      }
      if(document.getElementById("adressline1").value.length<8){
        outputmsg = outputmsg+t("debug7")
        document.getElementById("adressline1").style.border="2px solid red";
      }
      if(document.getElementById("adresscity").value.length<2){
        outputmsg = outputmsg+t("debug8")
        document.getElementById("adresscity").style.border="2px solid red";
      }
      if(document.getElementById("adresspostcode").value.length<2){
        outputmsg = outputmsg+""+t("debug9")
        document.getElementById("adresspostcode").style.border="2px solid red";
      }
      if(document.getElementById("adressname").value.length<2){
        outputmsg = outputmsg+""+t("debug10")
        document.getElementById("adressname").style.border="2px solid red";
      }
      if(document.getElementById("adresslastname").value.length<2){
        outputmsg = outputmsg+t("debug11")
        document.getElementById("adresslastname").style.border="2px solid red";
      }
      //alert("phn: "+parseInt(document.getElementById("adressphonenumber").replaceAll("(","").replaceAll(")","").replaceAll("+","").replaceAll("-","").replaceAll(" ", "")));
      if(parseInt(document.getElementById("adressphonenumber").value.replaceAll("(","").replaceAll(")","").replaceAll("+","").replaceAll("-","").replaceAll(" ", ""))<70000000001 || document.getElementById("adressphonenumber").value.length<10){
        outputmsg = outputmsg+t("debug12")
        document.getElementById("adressphonenumber").style.border="2px solid red";
      }
      if(document.getElementById("selecter").value === "chooseacategory"){
        outputmsg = outputmsg+t("debug13")
        document.getElementById("selecter").style.border="2px solid red";
      }
      if(outputmsg === "")
      return true;
      else {
        alert(outputmsg);
        setDisable(false);
        return false;}
    }
    const [signedin, setSignedIn] = useState(false);
    const changesignstatus = (mybool) => setSignedIn(mybool);
    const [isdisabled, setDisable] = useState(false);
    const placeorder = () =>{

        setDisable(true);
        if(signedin){
                  if(validateproductinputs()){
          db.ref("/orders/"+IdFromURL).set({
              productlink: document.getElementById("productlink").value,
              productname: document.getElementById("productname").value,
              productcode: document.getElementById("productcode").value,
              productsize: document.getElementById("productsize").value,
              productcolor: document.getElementById("productcolor").value,
              productquantity: document.getElementById("productquantity").value,
              productpriceintl: document.getElementById("productpriceintl").value,
              productervestafee: document.getElementById("productervestafee").placeholder,
              producttotalcost: document.getElementById("producttotalcost").placeholder,
              productcomment: document.getElementById("productcomment").value,
              useruid: useruid,
              usermail: usermail,
              status: "waiting for payment",
              date: "edited: "+getDate(),
              adressline1: document.getElementById("adressline1").value,
              adresscity: document.getElementById("adresscity").value,
              adresspostcode: document.getElementById("adresspostcode").value,
              adresscountry: document.getElementById("adresscountry").placeholder,
              adressphonenumber: document.getElementById("adressphonenumber").value,
              adressname: document.getElementById("adressname").value,
              adresslastname: document.getElementById("adresslastname").value,
              material: document.getElementById("selecter").value,
            })
            .then(() => {
                    console.log("Data set.")
                    db.ref("/users/"+useruid+"/orders/"+IdFromURL).set({
                      productlink: document.getElementById("productlink").value,
                      productname: document.getElementById("productname").value,
                      productcode: document.getElementById("productcode").value,
                      productsize: document.getElementById("productsize").value,
                      productcolor: document.getElementById("productcolor").value,
                      productquantity: document.getElementById("productquantity").value,
                      productpriceintl: document.getElementById("productpriceintl").value,
                      productervestafee: document.getElementById("productervestafee").placeholder,
                      producttotalcost: document.getElementById("producttotalcost").placeholder,
                      productcomment: document.getElementById("productcomment").value,
                      useruid: useruid,
                      usermail: usermail,
                      status: "waiting for payment",
                      date: "edited: "+getDate(),
                      adressline1: document.getElementById("adressline1").value,
                      adresscity: document.getElementById("adresscity").value,
                      adresspostcode: document.getElementById("adresspostcode").value,
                      adresscountry: document.getElementById("adresscountry").placeholder,
                      adressphonenumber: document.getElementById("adressphonenumber").value,
                      adressname: document.getElementById("adressname").value,
                      adresslastname: document.getElementById("adresslastname").value,
                      material: document.getElementById("selecter").value,
                    })
                    .then(() => {
                      console.log("order saved on profile")
                      
                            setDisable(false);
                            window.location.href = "./post-order?uid="+JSON.stringify(IdFromURL).replaceAll("\"","");
                            console.log("adress saved on profile")
                          
                    })
            })}
          }else{
              
          }
      
      };

  return (
    <View style={styles.container1}>
        
      <View style={styles.container2}>
            <h1 > Edit your order: {IdFromURL} <i class="fa-solid fa-pen"></i></h1>
            
            <div className="form2" >
              
            <div className="form-body">
            <div className="username">
                  <Text id="pldisc" className="" >{t("productlink")}*</Text>
                  <input maxlength="10000" className="form__input" type="text" id="productlink" placeholder="https://www.dyson.com.tr/dyson-airwrap-styler-complete-long-iron-fuchsia"/>
                  <Text className="form__smalltext">{t("chooseproducts")}<Link className="form__label" to='/products'>brands</Link> page to learn all available brands.</Text><br/>
              </div>
              <div className="form-body2">
                <div>
                    <Text id="pndisc" className="" >{t("productname")}*</Text><br/>
                    <input maxlength="300" type="text" name="" id="productname"  className="form__halfinput" placeholder="For example: Shirt"/>
                </div>
                <div>
                    <Text className="" >{t("productcode")}</Text><br/>
                    <input maxlength="100" type="text" name="" id="productcode"  className="form__halfinput" placeholder="For example: 1234/567"/>
                </div>
                <Text className="form__smalltext" ><br/>{t("nameoftheproductasin")}</Text><br/>
              </div>
              <div/>
              <div div className="form-body2">
                  <div className="password">
                    <Text id="psdisc" className="" >{t("productsize")}*</Text><br/>
                    <input maxlength="10" type="text" id="productsize"  className="form__halfinput" placeholder="For example: L or 32"/>
                  </div>
                  <div className="password">
                    <Text id="pcdisc" className="" >{t("productcolor")}*</Text><br/>
                    <input maxlength="30" className="form__halfinput" type="text"  id="productcolor" placeholder="For example: Green"/>
                  </div>
                  <div className="confirm-password">
                    <Text  className="" >{t("productquantity")}*</Text><br/>
                    <input  className="form__halfinput" onChange={handleInputTextChange} min="1" max="20" type="number" id="productquantity" placeholder="1" defaultValue="1"/>
                  </div>
              </div>
              <Text className="" >{t("productcategory2")}*</Text>
              <select id="selecter" onChange={handleInputTextChange} className="form__halfinput" style={{marginLeft: 5, width:200,height:45}} name="cars">
                <option value="chooseacategory">{t("chooseacategory")}</option>
                <option value="nonmetal">{t("nonmetal")}</option>
                <option value="metal">{t("metal")}</option>
              </select>
              <div div className="form-body2">
                  <div className="password">
                    <Text id="pitldisc" className="" >{t("productpriceintl")}*</Text><br/>
                    <input maxlength="20" type="text" id="productpriceintl" onChange={handleInputTextChange}  className="form__halfinput" placeholder="For example: 99.90 TL"/>
                    <Text className="form__smalltext" ><br/>1 {t("turkishlira")} = {trykztrate} {t("kazakhtenge")}.</Text><br/>
                  </div>
                  <div className="password">
                    <Text className="" >{t("productervestafee")}</Text><br/>
                    <input  disabled className="form__halfinput" type="text"  id="productervestafee" placeholder="20% fee"/>
                  </div>
                  <div className="confirm-password">
                    <Text className="" >{t("producttotalcost")}</Text><br/>
                    <input  disabled className="form__halfinput" type="number" id="producttotalcost" placeholder="0 KZT" value="0 KZT"/>
                  </div>
              </div>
              <div div className="form-body">
                  <div className="password">
                    <Text  >{t("productcomment")}</Text><br/>
                    <input maxlength="2000" type="text" id="productcomment"  className="form__input" placeholder="Tell us if you need anything special"/>
                  </div>
              </div>
              <br/>
              <text className="form__titellabel" for="firstName">{t("adressandrecieverinformation")}</text>
              <Text className="" >{t("makesureyouradressis")}</Text>
              
              <div div className="form-body">
                  <div className="password">
                    <Text id="al1disc" className="" >{t("adressline1")}*</Text><br/>
                    <input maxlength="2000" type="text" id="adressline1"  className="form__input" placeholder="Your adress formal description"/>
                  </div>
                  
              </div>
              <div div className="form-body2">
                  <div >
                    <Text id="acdisc" className="" >{t("adresscity")}*</Text><br/>
                    <input maxlength="50" type="text" id="adresscity"  className="form__halfinput" placeholder="Almaty, Nur-Sultan etc."/>
                  </div>
                  <div >
                    <Text id="apcdisc" className="" >{t("adresspostcode")}*</Text><br/>
                    <input maxlength="12" type="text" id="adresspostcode"  className="form__halfinput" placeholder="For example: 050000"/>
                  </div>
                  <div >
                    <Text className="" >{t("adresscountry")}*</Text><br/>
                    <input disabled type="text" id="adresscountry"  className="form__halfinput" placeholder="KAZAKHSTAN"/>
                  </div>
              </div>
              <div div className="form-body2">
                  <div >
                    <Text id="apndisc" className="" >{t("adressphonenumber")}*</Text><br/>
                    <MaskInput id="adressphonenumber" className="form__halfinput"  mask={'+7 (000) 000-00-00'}  maskChar="_" placeholder="+7 (000) 000-00-00"/>
                  </div>
                  <div >
                    <Text id="afndisc" className="" >{t("adressname")}*</Text><br/>
                    <input maxlength="50" type="text" id="adressname"  className="form__halfinput" placeholder="Name of receiver"/>
                  </div>
                  <div >
                    <Text id="alndisc" className="" >{t("adresslastname")}*</Text><br/>
                    <input maxlength="50" type="text" id="adresslastname"  className="form__halfinput" placeholder="Surname of receiver"/>
                  </div>
              </div>
         
              </div>
              
          </div>
          
          <View >
              <button disabled={isdisabled} className= "btn--disabled" onClick={placeorder} style={styles.buttoncontainer} >SAVE CHANGES <i class="fa-solid fa-floppy-disk"></i></button> 
              <button className= "btn--disabled" style={styles.smallerbuttoncontainer} onClick={()=> window.location.href = "./profile"}>CANCEL CHANGES <i class="fa-solid fa-xmark"></i></button>   
          </View>


      </View>
    </View>
  );
}

const styles = {
    container1: {
      marginTop: 55,
      flexDirection: "row",
      textAlign: 'center',
      flex: 1,
    },
    container2: {
      marginTop: 10,
      flexDirection: "column",
      textAlign: 'center',
      flex: 1,
    },
    container: {
      marginTop: 5,
      flexDirection: "row",
      marginBottom: 5,
      flex: 1
    },
    buttoncontainer: {
        marginRight: "30%",
        marginLeft: "30%",
        textAlign: 'center',
        marginTop: 5,
    },
    smallerbuttoncontainer: {
        marginRight: "35%",
        marginLeft: "35%",
        textAlign: 'center',
        marginTop: 5,
        marginBottom:20
    },
    imagecontainer: {
        width: 120,
        height: 120,
        textAlign: 'center',
        marginBottom: 5,
    },
    rowcontainer2: {
        width: "100%",
        textAlign: 'center',
        flexDirection: "row",
        justifyContent: 'center',
        flex:1,
    },
    infobarcontainer: {
      textAlign: 'left',
      marginBottom: 5,
      flex:5,
    },
    statuscontainer: {
      textAlign: 'center',
      marginBottom: 0,
      marginLeft: 5,
      flex:1.2,
    },
    actionscontainer: {
      textAlign: 'center',
      marginBottom: 5,
      marginLeft: 5,
      flex:1.2,
      justifyContent: 'space-between',
    },
    rowcontainer: {width: "95%",
      textAlign: 'left',
      flexDirection: "row",
      marginBottom: 5,
      justifyContent: 'space-between',
      flex:1.2,
    }
  }

export default PostOrder;

