import React,{ useState, useEffect } from "react";
import {Link} from "react-router-dom";
import CardItem from "./CardItem";
import "./Cards.css";
import "./HeroSection.css";

function SlidingBrands() {

  return (
        <div className="sliding-background" >
            <div className="cards__wrapper">
               <div className="cards__wrapper">
                    <ul className="cards__items">
                        <CardItem 
                        src="https://logowik.com/content/uploads/images/trendyolcom2977.jpg"
                        text="trendyol.com" 
                        label="All Products"
                        href="https://trendyol.com"
                        path="https://trendyol.com" />

                        <CardItem 
                        src="https://static.tildacdn.com/tild3163-6638-4531-a138-646239396631/Logo-1.jpg"
                        text="https://www.dyson.com.tr" 
                        label="Textile Products"
                        path="/services" />
                                                <CardItem 
                        src="https://static.tildacdn.com/tild3763-6135-4366-a530-396234383739/190602_175.jpg"
                        text="trendyol.com" 
                        label="All Products"
                        path="/services" />

                        <CardItem 
                        src="https://static.tildacdn.com/tild3763-6135-4366-a530-396234383739/190602_175.jpg"
                        text="mavifghdfgsdgdsfg.com" 
                        label="Textile Products"
                        path="/services" />
                                                <CardItem 
                        src="https://static.tildacdn.com/tild3763-6135-4366-a530-396234383739/190602_175.jpg"
                        text="massimodutti.com" 
                        label="All Products"
                        path="/services" />

                        <CardItem 
                        src="https://static.tildacdn.com/tild3763-6135-4366-a530-396234383739/190602_175.jpg"
                        text="mavi.com" 
                        label="Textile Products"
                        path="/services" />
                                                <CardItem 
                        src="https://logowik.com/content/uploads/images/trendyolcom2977.jpg"
                        text="trendyol.com" 
                        label="All Products"
                        path="/services" />

                        <CardItem 
                        src="https://static.tildacdn.com/tild3763-6135-4366-a530-396234383739/190602_175.jpg"
                        text="mavi.com" 
                        label="Textile Products"
                        path="/services" />
                        <CardItem 
                        src="https://logowik.com/content/uploads/images/trendyolcom2977.jpg"
                        text="trendyol.com" 
                        label="All Products"
                        path="/services" />

                        <CardItem 
                        src="https://static.tildacdn.com/tild3763-6135-4366-a530-396234383739/190602_175.jpg"
                        text="maviddfd.com" 
                        label="Textile Products"
                        path="/services" />
                                                <CardItem 
                        src="https://static.tildacdn.com/tild3163-6638-4531-a138-646239396631/Logo-1.jpg"
                        text="https://www.dyson.com.tr" 
                        label="Textile Products"
                        path="/services" />
                                                <CardItem 
                        src="https://logowik.com/content/uploads/images/trendyolcom2977.jpg"
                        text="trendyol.com" 
                        label="All Products"
                        path="/services" />

                        <CardItem 
                        src="https://static.tildacdn.com/tild3763-6135-4366-a530-396234383739/190602_175.jpg"
                        text="mavifghdfgsdgdsfg.com" 
                        label="Textile Products"
                        path="/services" />
                                                <CardItem 
                        src="https://logowik.com/content/uploads/images/trendyolcom2977.jpg"
                        text="trendyol.com" 
                        label="All Products"
                        path="/services" />





                    </ul>
                </div>
                    
            </div>
        </div>
  );
}

export default SlidingBrands;
