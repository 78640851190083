

// Import Firestore database
import { db,auth,user } from '../firebase';
import React, { useState,useEffect } from 'react';
import './pages/pagescss/signup.css';
import { Button } from "./Button";
import { View, Text,Image,CheckBox} from 'react-native';
import { Link } from 'react-router-dom';
import MaskInput from 'react-maskinput';
import '../languages/i18n'; 
import {useTranslation} from 'react-i18next';
//import './read.css';

const ListItem = ({myparam}) => {
  
    const [info , setInfo] = useState([]);
  
    const [uidtext, setuidtext] = useState("uid");
    const changeuidtext = (text) => setuidtext(text);

    const [cars, setCars] = useState([]);
      
    
    const [iseditmode , setiseditmode] = useState(false);
    const [isSelected, setSelection] = useState(true);
    
    //alert("list item");
    // Start the fetch operation as soon as
    // the page loads
    /*
    window.addEventListener('load', () => {
        Fetchdata();
      });*/
    // Fetch the required data using the get() method
    const Fetchdata = ()=>{
        auth.onAuthStateChanged(function(user) {
            if (user) {
            //changesignstatus(true);
            
            changeuidtext(user.uid)
            
        db.ref("users/"+user.uid+"/orders/").once("value").then((querySnapshot) => {
             
            changeuidtext(user.uid);
            // Loop through the data and store
            // it in array to display
            var i = 0;
            const newCar = [];
            querySnapshot.forEach(element => {
                var data = element.val();
                newCar[i] = element.key; 
                setInfo(arr => [...arr , data]);
                i++;
            });
            setCars(newCar);
        })
            }else {
                //changesignstatus(false);
            }
        });
    }
    useEffect(()=> {
        
        setInfo([]);
        Fetchdata();
    }, [myparam]);
    // Display the result on the page
    return (
        <div>
          
        {
            info.map((data,index) => (
            <Frame  
                ordernumber={cars[index]} 
                productlink={data.productlink} 
                productname={data.productname} 
                productsize={data.productsize} 
                productcolor={data.productcolor} 
                productcode={data.productcode} 
                productquantity={data.productquantity} 
                productpriceintl={data.productpriceintl} 
                productervestafee={data.productervestafee} 
                producttotalcost={data.producttotalcost} 
                productcomment={data.productcomment}
                status={data.status}
                date={data.date}
                useruid={data.useruid} 
                adressline1={data.adressline1} 
                adressline2={data.adressline2} 
                adresscity={data.adresscity} 
                adresspostcode={data.adresspostcode} 
                adresscountry={data.adresscountry} 
                adressphonenumber={data.adressphonenumber}
                adressname={data.adressname} 
                adresslastname={data.adresslastname}   />
            ))
        }
        </div>
  
    );
}
                 
const handleInputTextChange = () =>{
    document.getElementById("productervestafee").placeholder = (parseInt(document.getElementById("productquantity").value)*parseInt(document.getElementById("productpriceintl").value)*0.2*30).toFixed(2)+" KZT";
    document.getElementById("producttotalcost").placeholder=
    parseInt(document.getElementById("productquantity").value)*parseInt(document.getElementById("productpriceintl").value)*30
    +parseInt(document.getElementById("productervestafee").placeholder) +" KZT";
    
};
function shouldshoweditbtn(statusval) {
  if(statusval === "waiting for payment")
        return true;
   else 
        return false;
    
};
// Define how each display entry will be structured
const Frame = ({ordernumber, productlink,productname, productsize,
    productcolor,productcode,productquantity,productpriceintl,
    productervestafee,producttotalcost,productcomment,status,date,useruid,adressline1,
    adressline2,adresscity,adresspostcode,adresscountry,adressphonenumber,adressname,adresslastname}) => {
        
    const [cancelshow, setcancelshow] = useState(false);
    function deleteorder(){
        setcancelshow(false);
        db.ref("users/"+useruid+"/orders/").child(ordernumber).remove().then(()=>{
            db.ref("orders/").child(ordernumber).remove().then(()=>{
                window.location.href = "./profile";
            });
        });
    };
    const {t, i18n} = useTranslation();

    return (
        <div className="form2" >
            <View style={styles.container}>
            <View style={styles.headiconcontainer} >
                <Image source="/images/order.png" style={{width:40,height:40,top:10,resizeMode: "contain",}} />
                </View>
                <View style={styles.infobarcontainer} >
                    <View style={styles.rowcontainer}>
                        <text style={styles.a}><Text>{t("ordernumber")}: </Text>{ordernumber}</text>
                        <text style={styles.a}><Text>{t("orderdate")}: </Text>{date}</text>
                    </View>
                    <text ><Text>{t("productlink")}: </Text>{productlink}</text>
                    <View style={styles.rowcontainer}>
                        <text style={styles.a}><Text>{t("productname")}: </Text>{productname}</text>
                        <text style={styles.a}><Text>{t("productcode")}: </Text>{productcode}</text>
                    </View>
                    <View style={styles.rowcontainer}>
                        <text style={styles.a}><Text>{t("productsize")}: </Text>{productsize}</text>
                        <text style={styles.a}><Text>{t("productcolor")}: </Text>{productcolor}</text>
                        <text style={styles.a}><Text>{t("productquantity")}: </Text>{productquantity}</text>
                    </View>
                    <View style={styles.rowcontainer}>
                        <text style={styles.a}><Text>{t("productpriceintl")}: </Text>{productpriceintl}</text>
                        <text style={styles.a}><Text>{t("productervestafee")}: </Text>{productervestafee}</text>
                        <text style={styles.a}><Text>{t("producttotalcost")}: </Text>{producttotalcost}</text>
                    </View>    
                    <text ><Text>Adress: </Text>{adressline1}</text>
                    <text >{adressline2}</text>
                    <View style={styles.rowcontainer}>
                        <text style={styles.a} className="" ><Text>{t("adresscity")}: </Text>{adresscity}</text>
                        <text style={styles.a} className="" ><Text>{t("adresspostcode")}: </Text>{adresspostcode}</text>
                        <text style={styles.a} className="" >{adresscountry}</text>
                    </View>  
                    <View style={styles.rowcontainer}>
                        <text style={styles.a} className="" ><Text>{t("adressname")}: </Text>{adressname}</text>
                        <text style={styles.a} className="" ><Text>{t("adresslastname")}: </Text>{adresslastname}</text>
                        <text style={styles.a} className="" >{adressphonenumber}</text>
                    </View>  
                    <text className="" ><Text>{t("productcomment")}: </Text>{productcomment}</text>
            </View>
            <View style={styles.statuscontainer} >
            <text ><Text>Status: </Text>{status}</text>   

                    {(status === "waiting for payment")&&<text >{t("send")} {producttotalcost} {t("tothisaccount")}: 4405 6397 0583 7399 - Halyk Bank - Zhazira Zhumabayeva</text> }
                    {(status === "waiting for payment")&&<text >Kaspi Bank </text>  }
                    {(status === "waiting for payment")&&<Image source={"/images/payment.png"} style={{flex:1, left:0,top:5,minHeight:"60px", resizeMode: "contain",}} />}
                    {(status === "waiting for payment")&&<text style={styles.centertextdescred} >  {t("youshoulput")}({ordernumber}) {t("inthedescription")}</text>  }
                    {(status === "waiting for payment")&&<text style={styles.centertext}><i class="fa-solid fa-clock"></i> {t("paymentwillbeverified")} {t("12hours")}</text>}

                    {(status === "waiting for supply")&&<text style={styles.centertextdescgreen}>{t("paymentverified")}</text> }
                    {(status === "waiting for supply")&&<Image source={"/images/supply.png"} style={{flex:1, left:0,top:5,resizeMode: "contain",}} />}
                    {(status === "waiting for supply")&&<text > {t("ervestawillbuy")}</text>  }
                    {(status === "waiting for supply")&&<text style={styles.centertext}><i class="fa-solid fa-clock"></i> {t("thisstepwilltake")} {t("23days")}</text>}

                    {(status === "shipped")&&<text >{t("productsuppliedshipped")}</text> }
                    {(status === "shipped")&&<Image source={"/images/shipped2.png"} style={{flex:1, left:0,top:5,resizeMode: "contain",}} />}
                    {(status === "shipped")&&<text >{t("youwillsoon")}</text>  }
                    {(status === "shipped")&&<text style={styles.centertext}><i class="fa-solid fa-clock"></i> {t("thisstepwilltake")} {t("10days")}</text>}

                    {(status === "delivered")&&<text >{t("productdelivered")}</text> }
                    {(status === "delivered")&&<Image source={"/images/delivered.png"} style={{flex:1, left:0,top:5,resizeMode: "contain",}} />}
                    {(status === "delivered")&&<text >{t("enjoyproduct")}</text>  }


            </View>
            <View style={styles.actionbarcontainer} >
            <text >{t("actions")}</text>
                {(!cancelshow && shouldshoweditbtn(status)) && <Button buttonStyle= "btn--outline2" onClick={()=>{setcancelshow(true);setTimeout(() => {setcancelshow(false);}, 2000)}}>{t("cancelorder")} <i class="fa-solid fa-xmark"></i></Button>}
                {(cancelshow && shouldshoweditbtn(status)) && <Button buttonStyle="btn--outlinered" onClick={deleteorder}>{t("cancelpermanent")}</Button>}
                {shouldshoweditbtn(status) && <Button buttonStyle= "btn--outline2" onClick={()=>window.location.href = "./edit-order?uid="+JSON.stringify(ordernumber).replaceAll("\"","")}>{t("editorder")} <i class="fa-solid fa-pen"></i>  </Button> }
                <Button buttonStyle= "btn--outline2" onClick={()=>window.location.href = "./get-help?uid="+JSON.stringify(ordernumber).replaceAll("\"","")} >{t("gethelp")} <i class="fa-solid fa-circle-info"></i></Button>     
            </View>
            </View>

        </div>

    );
}
const styles = {
  container: {
    marginTop: 5,
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 5,
    flex: 1
  },
  headiconcontainer: {
    textAlign: 'center',
    marginBottom: 10,
    minWidth:100,
    marginLeft: 5,
    flex:1.2,
  },    
  infobarcontainer: {
    textAlign: 'left',
    marginBottom: 5,
    minWidth: "300px",
    flexWrap: "wrap",
    flex:6,
  },
  statuscontainer: {
    textAlign: 'center',
    minWidth: "200px",
    marginBottom: 0,
    marginLeft: 5,
    flex:1.6,
  },
  actionbarcontainer: {
    textAlign: 'center',
    marginBottom: 0,
    marginLeft: 5,
    flex:1.4,
  },
  centertextdescred: {
      color: "red",
      textAlign: 'center',
      flexDirection: "column",
      marginTop: 2,
      justifyContent: 'center',
  },
  centertextdescgreen: {
      color: "green",
      textAlign: 'center',
      flexDirection: "column",
      marginTop: 2,
      justifyContent: 'center',
  },
  actionscontainer: {
    textAlign: 'center',
    marginBottom: 5,
    marginLeft: 5,
    flex:1.2,
    justifyContent: 'space-between',
  },
  rowcontainer: {width: "95%",
    textAlign: 'left',
    flexDirection: "column",
    flexWrap: "wrap",
    marginBottom: 5,
    justifyContent: 'space-between',
    flex:1.2,
  },
  a:{
    width: "33%",
   }
}
export default ListItem;