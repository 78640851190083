import React from "react";
import "../../App.css";
import Cards from "../Cards";
import Footer from "../Footer";
function Products() {
 
    

  return (
    <>
      <Cards/>
      <Footer/>
    </>
  );
}

export default Products;