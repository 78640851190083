// firebase.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import 'firebase/compat/storage';

const fire = firebase.initializeApp({
    apiKey: "AIzaSyDUwxRsudr1a0rfNMXvi0j2IVRgOZ-2KV4",
    authDomain: "ervesta.firebaseapp.com",
    projectId: "ervesta",
    storageBucket: "ervesta.appspot.com",
    messagingSenderId: "997164727639",
    appId: "1:997164727639:web:3f426d6978c2c69cf39212"
});

export const auth = fire.auth();
export const user = fire.auth().currentUser;
export const fsdb = fire.firestore();
export const db = fire.database();
export default {
  fire,
};