
import React from "react";
import "../../App.css";
import Cards from "../Cards";
import Whyervesta from "../Whyervesta";
import HeroSection from "../HeroSection";
import Footer from "../Footer";
import ForCostumer from "../ForCostumer";
function Home() {
  
  return (
    <>
      <HeroSection />
      <Whyervesta/>
      <ForCostumer/>
      <Cards/>
      <Footer/>
    </>
  );
}

export default Home;



