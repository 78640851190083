

import {Text,Image,View,CheckBox} from "react-native";
import SpesificOrder from '../SpesificOrder';
import { db,auth,user } from '../../firebase';
import React, { useState } from 'react';
import './pagescss/signup.css';
import StepChart from '../StepsChart';
import { Button } from "../Button";
import MaskInput from 'react-maskinput';
import { Link } from 'react-router-dom';
import '../../languages/i18n'; 
import {useTranslation} from 'react-i18next';

function PostOrder() {
    const {t, i18n} = useTranslation();
 
    const search = window.location.search; // returns the URL query String
    const params = new URLSearchParams(search); 
    const IdFromURL = params.get('uid'); 
    
    const [info , setInfo] = useState([]);
    const [iseditmode , setiseditmode] = useState(false);
  
    const [uidtext, setuidtext] = useState("uid");
    const changeuidtext = (text) => setuidtext(text);

    const [cancelshow, setcancelshow] = useState(false);

    const [productlink, setproductlink] = useState("productlink");
    const [productname, setproductname] = useState("productname");
    const [productcode, setproductcode] = useState("productcode");
    const [productcolor, setproductcolor] = useState("productcolor");
    const [productpriceintl, setproductpriceintl] = useState("productpriceintl");
    const [productquantity, setproductquantity] = useState("productquantity");
    const [productsize, setproductsize] = useState("productsize");
    const [producttotalcost, setproducttotalcost] = useState("producttotalcost");
    const [productervestafee, setproductervestafee] = useState("productervestafee");
    const [productcomment, setproductcomment] = useState("productcomment");
    const [status, setstatus] = useState("status");
    const [useruid, setuseruid] = useState("useruid");
    const [usermail, setusermail] = useState("usermail");
    const [date, setdate] = useState("date");
    const [adresscity, setadresscity] = useState("adresscity");
    const [adresscountry, setadresscountry] = useState("adresscountry");
    const [adresslastname, setadresslastname] = useState("adresslastname");
    const [adressline1, setadressline1] = useState("adressline1");
    const [adressname, setadressname] = useState("adressname");
    const [adressphonenumber, setadressphonenumber] = useState("adressphonenumber");
    const [adresspostcode, setadresspostcode] = useState("adresspostcode");

    const [ordernumber, setordernumber] = useState("ordernumber");
      
    const handleInputTextChange = () =>{
        document.getElementById("productervestafee").placeholder = (parseInt(document.getElementById("productquantity").value)*parseInt(document.getElementById("productpriceintl").value)*0.2*30).toFixed(2)+" KZT";
        document.getElementById("producttotalcost").placeholder=
        parseInt(document.getElementById("productquantity").value)*parseInt(document.getElementById("productpriceintl").value)*30
        +parseInt(document.getElementById("productervestafee").placeholder) +" KZT";
        
    };

    auth.onAuthStateChanged(function(user) {
        if (user) {
        //changesignstatus(true);
        changeuidtext(user.uid)
            db.ref("users/"+user.uid+"/orders/"+IdFromURL).once("value").then((querySnapshot) => {
                setordernumber(IdFromURL);
                setproductlink(querySnapshot.child("productlink").val());
                setproductname(querySnapshot.child("productname").val());
                setproductcode(querySnapshot.child("productcode").val());
                setproductcolor(querySnapshot.child("productcolor").val());
                setproductpriceintl(querySnapshot.child("productpriceintl").val());
                setproductquantity(querySnapshot.child("productquantity").val());
                setproductsize(querySnapshot.child("productsize").val());
                setproducttotalcost(querySnapshot.child("producttotalcost").val());
                setproductervestafee(querySnapshot.child("productervestafe").val());
                setproductcomment(querySnapshot.child("productcomment").val()); 
                setstatus(querySnapshot.child("status").val());
                setuseruid(querySnapshot.child("useruid").val());
                setdate(querySnapshot.child("date").val());
                
                setadresscity(querySnapshot.child("adresscity").val());
                setadresscountry(querySnapshot.child("adresscountry").val());
                setadresslastname(querySnapshot.child("adresslastname").val());
                setadressline1(querySnapshot.child("adressline1").val());
                setadressname(querySnapshot.child("adressname").val());
                setadressphonenumber(querySnapshot.child("adressphonenumber").val());
                setadresspostcode(querySnapshot.child("adresspostcode").val());

                
            })
        }else {
            //changesignstatus(false);
        }
    });
    function shouldshoweditbtn(statusval) {
        if(statusval === "waiting for payment")
              return true;
         else 
              return false;
          
      };
    function deleteorder(){
        setcancelshow(false);
        db.ref("users/"+useruid+"/orders/").child(IdFromURL).remove().then(()=>{
            window.location.href = "./profile";
        });
    };

  return (
      <div className="body">
    <View >
        
      <View style={styles.container2}>
            <h1 > {t("yourorderhasbeen")}</h1>
            <Text >{t("yourordernumis")}: {IdFromURL}. </Text>
            <View style={styles.rowcontainer2}>
                <Image style={styles.imagecontainer} source="/images/checked.png"  />
            </View>
            <br/>
            <div className="form2" >
            
            <View style={styles.container}>
            <View style={styles.statuscontainer} >
                <Image source="/images/order.png" style={{width:100,height:100,resizeMode: "contain",}} />
            </View>
            <View style={styles.infobarcontainer} >
                    <View style={styles.rowcontainer}>
                        <text style={styles.a}><Text>{t("ordernumber")}: </Text>{ordernumber}</text>
                        <text style={styles.a}><Text>{t("orderdate")}: </Text>{date}</text>
                    </View>
                    <text ><Text>{t("productlink")}: </Text><br/>{productlink}</text>
                    <View style={styles.rowcontainer}>
                        <text style={styles.a}><Text>{t("productname")}: </Text><br/>{productname}</text>
                        <text style={styles.a}><Text>{t("productcode")}: </Text><br/>{productcode}</text>
                    </View>
                    <View style={styles.rowcontainer}>
                        <text style={styles.a}><Text>{t("productsize")}: </Text><br/>{productsize}</text>
                        <text style={styles.a}><Text>{t("productcolor")}: </Text><br/>{productcolor}</text>
                        <text style={styles.a}><Text>{t("productquantity")}: </Text><br/>{productquantity}</text>
                    </View>
                    <View style={styles.rowcontainer}>
                        <text style={styles.a}><Text>{t("productpriceintl")}: </Text><br/>{productpriceintl}</text>
                        <text style={styles.a}><Text>{t("productervestafee")}: </Text><br/>{productervestafee}</text>
                        <text style={styles.a}><Text>{t("producttotalcost")}: </Text><br/>{producttotalcost}</text>
                    </View>    
                    <text ><Text>Adress: </Text><br/>{adressline1}</text>
                    <View style={styles.rowcontainer}>
                        <text style={styles.a} className="" ><Text>{t("adresscity")}: </Text><br/>{adresscity}</text>
                        <text style={styles.a} className="" ><Text>{t("adresspostcode")}: </Text><br/>{adresspostcode}</text>
                        <text style={styles.a} className="" >{adresscountry}</text>
                    </View>  
                    <View style={styles.rowcontainer}>
                        <text style={styles.a} className="" ><Text>{t("adressname")}: </Text><br/>{adressname}</text>
                        <text style={styles.a} className="" ><Text>{t("adresslastname")}: </Text><br/>{adresslastname}</text>
                        <text style={styles.a} className="" >{adressphonenumber}</text>
                    </View>  
                    <text className="" ><Text>{t("productcomment")}: </Text><br/>{productcomment}</text>
            </View>
            <View style={styles.statuscontainer} >
                <text ><Text>{t("status")}: </Text>{status}</text>   

                {(status === "waiting for payment")&&<text >{t("send")} {producttotalcost} {t("tothisaccount")}: 4405 6397 0583 7399 - Halyk Bank - Zhazira Zhumabayeva</text> }
                {(status === "waiting for payment")&&<text >Kaspi Bank </text>  }
                {(status === "waiting for payment")&&<Image source={"/images/payment.png"} style={{flex:1, left:0,top:5,resizeMode: "contain",}} />}
                {(status === "waiting for payment")&&<text style={styles.centertextdescred} > {t("youshoulput")}({ordernumber}) {t("inthedescription")}</text>  }
                {(status === "waiting for payment")&&<text style={styles.centertext}><i class="fa-solid fa-clock"></i> {t("paymentwillbeverified")} {t("12hours")}</text>}

                {(status === "waiting for supply")&&<text style={styles.centertextdescgreen}>{t("paymentverified")}</text> }
                {(status === "waiting for supply")&&<Image source={"/images/supply.png"} style={{flex:1, left:0,top:5,resizeMode: "contain",}} />}
                {(status === "waiting for supply")&&<text > {t("ervestawillbuy")}</text>  }
                {(status === "waiting for supply")&&<text style={styles.centertext}><i class="fa-solid fa-clock"></i> {t("thisstepwilltake")} {t("23days")}</text>}

                {(status === "shipped")&&<text >{t("productsuppliedshipped")}</text> }
                {(status === "shipped")&&<Image source={"/images/shipped2.png"} style={{flex:1, left:0,top:5,resizeMode: "contain",}} />}
                {(status === "shipped")&&<text >{t("youwillsoon")}</text>  }
                {(status === "shipped")&&<text style={styles.centertext}><i class="fa-solid fa-clock"></i> {t("thisstepwilltake")} {t("10days")}</text>}
                
                {(status === "delivered")&&<text >{t("productdelivered")}</text> }
                {(status === "delivered")&&<Image source={"/images/delivered.png"} style={{flex:1, left:0,top:5,resizeMode: "contain",}} />}
                {(status === "delivered")&&<text >{t("enjoyproduct")}</text>  }


            </View>
            <View style={styles.statuscontainer} >
                <text >{t("actions")}</text>
                {(!cancelshow && shouldshoweditbtn(status)) && <Button buttonStyle= "btn--outline2" onClick={()=>{setcancelshow(true);setTimeout(() => {setcancelshow(false);}, 2000)}}>{t("cancelorder")} <i class="fa-solid fa-xmark"></i></Button>}
                {(cancelshow && shouldshoweditbtn(status)) && <Button buttonStyle="btn--outlinered" onClick={deleteorder}>{t("cancelpermanent")}</Button>}
                {shouldshoweditbtn(status) && <Button buttonStyle= "btn--outline2" onClick={()=>window.location.href = "./edit-order?uid="+JSON.stringify(ordernumber).replaceAll("\"","")}>{t("editorder")} <i class="fa-solid fa-pen"></i>  </Button> }
                <Button buttonStyle= "btn--outline2" onClick={()=>window.location.href = "./get-help?uid="+JSON.stringify(ordernumber).replaceAll("\"","")} >{t("gethelp")} <i class="fa-solid fa-circle-info"></i></Button>     
            </View>
            </View>
            
        </div>
            <View>
                <h1>{t("whatnext")}</h1>
            </View>
            <View style={styles.rowcontainer3} >
            <View style={styles.clomncontainerwn} >
               <View style={styles.rowcontainer2} >
                  <Image source="/images/number-1.png" style={styles.imagecontainer2} />
               </View>
                <text style={styles.centertext}>{t("waitingforpayment")}</text>
                <Text style={styles.centertextdesc}>{t("youshouldsend2")} {producttotalcost} {t("tothisaccount")}: 4405 6397 0583 7399 - Halyk Bank - Zhazira Zhumabayeva</Text>
                <Text style={styles.centertextdescred}>{t("youshoulput")}({ordernumber}) {t("inthedescription")}</Text>
                <br/>
                <View style={styles.rowcontainer2} >
                  <Image source="/images/payment.png" style={styles.imagecontainer2} />
               </View>
               <text style={styles.centertext}><i class="fa-solid fa-clock"></i> {t("lessthan12hours")}</text>
             </View>
            <Image source="/images/right-arrow.png" style={{flex:0.2, marginRight:5, top:5,resizeMode: "contain",}} />
             <View  style={styles.clomncontainerwn}>
               <View style={styles.rowcontainer2} >
                  <Image source="/images/number-2.png" style={styles.imagecontainer2} />
               </View>
               <text style={styles.centertext}>{t("pvwfs")}</text>
                <Text style={styles.centertextdesc}>{t("afteryoumadepayment")}</Text>
                <br/>
                <View style={styles.rowcontainer2} >
                  <Image source="/images/supply.png" style={styles.imagecontainer2} />
               </View>
                <text style={styles.centertext}><i class="fa-solid fa-clock"></i> {t("23days")}</text>
             </View>
            <Image source="/images/right-arrow.png" style={{flex:0.2, top:5,resizeMode: "contain",}} />
             <View  style={styles.clomncontainerwn}>
               <View style={styles.rowcontainer2} >
                  <Image source="/images/number-3.png" style={styles.imagecontainer2} />
               </View>
                <text style={styles.centertext}>{t("productsuppliedandshipped")}</text>
                <Text style={styles.centertextdesc}>{t("erevstausesvar")}</Text>
                <br/>
                <View style={styles.rowcontainer2} >
                  <Image source="/images/shipped2.png" style={styles.imagecontainer2} />
               </View>
                <text style={styles.centertext}><i class="fa-solid fa-clock"></i> {t("10days")}</text>
             </View>
             <Image source="/images/right-arrow.png" style={{flex:0.2, top:5,resizeMode: "contain",}} />
             <View  style={styles.clomncontainerwn}>
               <View style={styles.rowcontainer2} >
                     <Image source="/images/number-4.png" style={styles.imagecontainer2} />
               </View>
                <text style={styles.centertext}>{t("productdelivered")}</text>
                <Text style={styles.centertextdesc}></Text>
                <br/>
                <View style={styles.rowcontainer2} >
                  <Image source="/images/delivered.png" style={styles.imagecontainer2} />
               </View>
             </View>
             
        </View>
            <View>
            
            </View>
            <button style={styles.buttoncontainer} id="placeorderbtn" className= "btn--disabled" onClick={()=> window.location.href = "./profile"}>{t("gotomyprofile")}<i class="fa-solid fa-arrow-right"></i></button>

      </View>
    </View>
    </div>
  );
}

const styles = {
    container1: {
      marginTop: 55,
      flexDirection: "row",
      textAlign: 'center',
      flex: 1,
    },
    container222: {
      marginTop: 10,
      flexDirection: "row",
      textAlign: 'center',
      flex: 1,
    },
    container2: {
        marginTop: 10,
        flexDirection: "column",
        textAlign: 'center',
        flex: 1,
      },
    container: {
      marginTop: 5,
      flexDirection: "row",
      flexWrap: "wrap",
      marginBottom: 5,
      flex: 1
    },
    buttoncontainer: {
        marginRight: "30%",
        marginLeft: "30%",
        textAlign: 'center',
        marginTop: 5,
        marginBottom:50
    },
    smallerbuttoncontainer: {
        marginRight: "35%",
        marginLeft: "35%",
        textAlign: 'center',
        marginTop: 5,
        marginBottom:20
    },
    imagecontainer: {
        width: 120,
        height: 120,
        textAlign: 'center',
        marginBottom: 5,
    },
    imagecontainer2: {
        width: 50,
        height: 50,
        textAlign: 'center',
        marginBottom: 5,
    },
    rowcontainer2: {
        width: "100%",
        textAlign: 'left',
        flexDirection: "row",
        justifyContent: 'center',
        flex:1,
    },
    rowcontainer3: {
        marginLeft: "15%",
        marginRight: "15%",
        marginBottom: 50,
        width: "70%",
        textAlign: 'center',
        flexDirection: "row",
        justifyContent: 'center',
        flex:1,
    },
    clomncontainerwn: {
        textAlign: 'top',
        flexDirection: "column",
        flex:1,
    },
    centertext: {
        textAlign: 'center',
        flexDirection: "column",
        marginTop: 5,
        justifyContent: 'center',
    },
    centertextdesc: {
        textAlign: 'center',
        flexDirection: "column",
        marginTop: 2,
        justifyContent: 'center',
    },
    centertextdescred: {
        color: "red",
        textAlign: 'center',
        flexDirection: "column",
        marginTop: 2,
        justifyContent: 'center',
    },
    centertextdescgreen: {
        color: "green",
        textAlign: 'center',
        flexDirection: "column",
        marginTop: 2,
        justifyContent: 'center',
    },
    infobarcontainer: {
      textAlign: 'left',
      marginBottom: 5,
      minWidth: "300px",
      flexWrap: 'wrap',
      flex:5,
    },
    statuscontainer: {
      textAlign: 'center',
      marginBottom: 0,
      minWidth: "200px",
      marginTop: 10,
      marginLeft: 5,
      flex:1.2,
    },
    actionscontainer: {
      textAlign: 'center',
      marginBottom: 5,
      marginLeft: 5,
      flex:1.2,
      justifyContent: 'space-between',
    },
    rowcontainer: {
        width: "auto",
      textAlign: 'left',
      flexDirection: "row",
      marginBottom: 5,
      justifyContent: 'flex-start',
      flex:1.2,
    },
    a:{
        width: "33%",
        padding: 5,
        wordWrap: true,
        wrap: true,
    }
  }

export default PostOrder;

