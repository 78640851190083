//import '../../App.css';
import { db } from '../../firebase';
import { auth } from '../../firebase';
import './pagescss/signup.css';
import { Button } from "../Button";
import React,{useState, useEffect,Suspense } from 'react';
import { SafeAreaView ,StyleSheet, TextInput ,Text,Image,ScrollView,View,FlatList} from "react-native";
import { Link } from 'react-router-dom';
import Footer from "../Footer";
import { List} from 'native-base';
import ListItem from "../ListItem";
import AdressList from "../AdressList";
import '../../languages/i18n'; 
import {useTranslation} from 'react-i18next';

function Services() {
  const {t, i18n} = useTranslation();
  const [welcomeMessage, setwelcomeMessage] = useState("Welcome!");
  const changeMessage = (text) => setwelcomeMessage(text);

  const [uidtext, setuidtext] = useState("uid");
  const changeuidtext = (text) => setuidtext(text);

  const [nametext, setnametext] = useState("noname");
  const changenametext = (text) => setnametext(text);

  const [registerdatetext, setregisterdatetext] = useState("nodate");
  const changeregisterdatetext = (text) => setregisterdatetext(text);
  
  const [balancetext, setbalacetext] = useState("0");
  const changebalancetext = (text) => setbalacetext(text);
  
  const [adressname, setadressname] = useState("");
  const [adressline1, setadressline1] = useState("");
  const [adresscity, setadresscity] = useState("");
  const [adresspostcode, setadresspostcode] = useState("");
  const [adresscountry, setadresscountry] = useState("");
  const [adressphonenumber, setadressphonenumber] = useState("");
  const [adresslastname, setadresslastname] = useState("");

  const [adressinfo, setadressinfo] = useState([]);


  const [myordernum, setmyordernum] = useState(0);
  
  auth.onAuthStateChanged(function(user) {
      if (user) {
        //changesignstatus(true);
        changeMessage(""+user.email+"")
        changeuidtext(user.uid)
        db.ref("/users/"+user.uid)
        .once("value")
        .then(snapshot => {
          
          changenametext(snapshot.child("name").val()+" "+snapshot.child("surname").val());
          changeregisterdatetext(snapshot.child("registerdate").val());
          changebalancetext(snapshot.child("balance").val());

          //window.location.href = "./profile";
          
          setadressname(snapshot.child("adress").child("adressname").val());
          setadressline1(snapshot.child("adress").child("adressline1").val());
          setadresscity(snapshot.child("adress").child("adresscity").val());
          setadresspostcode(snapshot.child("adress").child("adresspostcode").val());
          setadresscountry(snapshot.child("adress").child("adresscountry").val());
          setadressphonenumber(snapshot.child("adress").child("adressphonenumber").val());
          setadresslastname(snapshot.child("adress").child("adresslastname").val());
        
        });

        var i = 0;
        db.ref("users/"+user.uid+"/orders/").once("value").then((querySnapshot) => {
             
          querySnapshot.forEach(element => {

              i++;
          });
          setmyordernum(i);
      })
        
      }else {
          //changesignstatus(false);
          changeMessage("Welcome!");
      }
  });
  function deleteadress(){
    db.ref("users/"+uidtext).child("adress").remove().then(()=>{
        window.location.href = "./profile";
    });
  };
  const _onPressButton = () =>{
    alert('Modal has been closed.');
    db.ref("/users/123").set({
        name: "zhard elace",
        age: 11,
      })
      .then(() => console.log("Data set."))};
      
    const signoutfun = () =>{
      auth.signOut().then(function() {
        console.log('Signed Out');
        window.location.href = "./";
      }, function(error) {
        console.error('Sign Out Error', error);
      });
    };

     
        useEffect(()=>{
          /*
          const data = db.ref("users/"+uidtext+"/orders/");
          data.on("value", datasnap =>{
            let data = [];
            datasnap.forEach((childsnap) => {
              let val = childsnap.val();
              val["$key"] = childsnap.key;
              data.push(val);
            })
            setmyData(data);
          })*/
          <ListItem myparam={""}/>
        },[adressline1]);

    return(
      
      <div className="topbody">
      <text className="form__titellabel" for="firstName">{t("myprofile")}</text><br />
      <text className="form__titellabel" for="firstName">{t("welcome")} {welcomeMessage}</text>
     
      <div className="form" >
        
        <text className="form__titellabel" >{t("profileinformaiton")}</text><br/>
          <div className="form-body2" >
            <Image source="/images/user1.png" style={{left:10,top:30, width: 100, height: 100}} />
            <div className="form-body3">
                    <text className="" >{t("balance")}: {balancetext} KZT</text>
                    <text className="" >{t("email")}: {welcomeMessage}</text>
                    <text className="" >{t("firstname")} {t("lastname")}: {nametext}</text>
                    <text className="" >{t("registirationdate")}: {registerdatetext}</text>
                    <text className="" >{t("uniqueID")}: {uidtext}</text><br/>
                    
            </div>
            
            {(adressline1 !== null) &&
              <View style={{left:30}} >
                  <text className="">My adress</text>
                      <View className="form-body3">
                          
                          <text className="" >{t("adressline1")}: {adressline1}</text>
                      </View>
                      <View className="form-body3">
                          <text className="" >{t("adresscity")}: {adresscity}</text>
                          <text className="" >{t("adresspostcode")}: {adresspostcode}</text>
                          <text className="" >{adresscountry}</text>
                      </View>  
                      <View className="form-body3">
                          <text className="" >{t("fistname")}: {adressname}</text>
                          <text className="" >{t("lastname")}: {adresslastname}</text>
                          <text className="" >{adressphonenumber}</text>
                          <Button  buttonStyle= "btn--outline2" onClick={deleteadress} >Delete <i class="fa-solid fa-trash"></i></Button>
                      </View> 
              </View>}
          </div>


          <div class="footer2">
            {<Button id="userbtn2" buttonStyle= "btn--outline2" onClick={signoutfun}>SIGN OUT</Button>}
          </div>
          
      </div>   
            
          <div className="form">
            <text className="form__titellabel" for="firstName">{t("myorders")}({myordernum}) <i class="fa-solid fa-receipt"></i></text><br />
              <div className="form-body">
                  <div className="username">
                      <text className="" >{t("listoforders")}</text>
                  </div>
              </div>
          <ListItem myparam={""}/>
          <div class="form-body">
              <Button buttonStyle= "btn--outline2" onClick={()=>window.location.href = "./services"}>PLACE NEW ORDER</Button>
          </div>
      </div>   
      <div style={{padding:"50px",textAlign:"center"}}>
         <text className="form__logotext" translate="no" to="/" >ERVESTA</text><br/>
        <text className="form__logotextslogan" translate="no" to="/"  >shop smart</text>
       </div>
      <Footer/>
      </div>  

    )       
}

const styles = {
  infobarcontainer: {
    backgroundColor: "#8D8D8D25",
    padding: "8px",
    textAlign: 'left',
    marginBottom: 5,
    width: "98%",
    borderRadius: "5px",
  },
  rowcontainer2: {width: "95%",
    textAlign: 'left',
    flexDirection: "row",
    marginBottom: 5,
    justifyContent: 'space-between',
    flex:1.2,
  }
}
export default  Services;
