

import {Text,Image,View,CheckBox} from "react-native";
import SpesificOrder from '../SpesificOrder';
import { db,auth,user } from '../../firebase';
import React, { useState } from 'react';
import './pagescss/signup.css';
import { Button } from "../Button";
import MaskInput from 'react-maskinput';
import { Link } from 'react-router-dom';
import '../../languages/i18n'; 
import {useTranslation} from 'react-i18next';

function PostOrder() {
 
    const search = window.location.search; // returns the URL query String
    const params = new URLSearchParams(search); 
    const IdFromURL = params.get('uid'); //ordernumber
    const IdFromURL2 = params.get('uid2'); //userid of order owner
    
    const [info , setInfo] = useState([]);
    const [iseditmode , setiseditmode] = useState(false);
    const {t, i18n} = useTranslation();
  
    const [uidtext, setuidtext] = useState("uid");
    const changeuidtext = (text) => setuidtext(text);

    const [isSelected, setSelection] = useState(true);

    const [productlink, setproductlink] = useState("productlink");
    const [productname, setproductname] = useState("productname");
    const [productcode, setproductcode] = useState("productcode");
    const [productcolor, setproductcolor] = useState("productcolor");
    const [productpriceintl, setproductpriceintl] = useState("productpriceintl");
    const [productquantity, setproductquantity] = useState("productquantity");
    const [productsize, setproductsize] = useState("productsize");
    const [producttotalcost, setproducttotalcost] = useState("producttotalcost");
    const [productervestafee, setproductervestafee] = useState("productervestafee");
    const [productcomment, setproductcomment] = useState("productcomment");
    const [status, setstatus] = useState("status");
    const [useruid, setuseruid] = useState("useruid");
    const [usermail, setusermail] = useState("usermail");
    const [date, setdate] = useState("date");
    const [adresscity, setadresscity] = useState("adresscity");
    const [adresscountry, setadresscountry] = useState("adresscountry");
    const [adresslastname, setadresslastname] = useState("adresslastname");
    const [adressline1, setadressline1] = useState("adressline1");
    const [adressname, setadressname] = useState("adressname");
    const [adressphonenumber, setadressphonenumber] = useState("adressphonenumber");
    const [adresspostcode, setadresspostcode] = useState("adresspostcode");

    const [ordernumber, setordernumber] = useState("ordernumber");
      
    const handleInputTextChange = () =>{
                 
        document.getElementById("productervestafee").placeholder = (parseInt(document.getElementById("productquantity").value)*parseInt(document.getElementById("productpriceintl").value)*0.2*30).toFixed(0)+" KZT";
        document.getElementById("producttotalcost").placeholder=
        parseInt(document.getElementById("productquantity").value)*parseInt(document.getElementById("productpriceintl").value)*30
        +parseInt(document.getElementById("productervestafee").placeholder) +" KZT";
        
      
    };

    auth.onAuthStateChanged(function(user) {
        if (user) {
        //changesignstatus(true);
        changeuidtext(user.uid)
        changesignstatus(true);
        //changeshowlogin(false);

            db.ref("orders/"+IdFromURL).once("value").then((querySnapshot) => {
                setordernumber(IdFromURL);
                setproductlink(querySnapshot.child("productlink").val());
                setproductname(querySnapshot.child("productname").val());
                setproductcode(querySnapshot.child("productcode").val());
                setproductcolor(querySnapshot.child("productcolor").val());
                setproductpriceintl(querySnapshot.child("productpriceintl").val());
                setproductquantity(querySnapshot.child("productquantity").val());
                setproductsize(querySnapshot.child("productsize").val());
                setproducttotalcost(querySnapshot.child("producttotalcost").val());
                setproductervestafee(querySnapshot.child("productervestafee").val());
                setproductcomment(querySnapshot.child("productcomment").val()); 
                setstatus(querySnapshot.child("status").val());
                setuseruid(querySnapshot.child("useruid").val());
                setusermail(querySnapshot.child("usermail").val());
                setdate(querySnapshot.child("date").val());
                
                setadresscity(querySnapshot.child("adresscity").val());
                setadresscountry(querySnapshot.child("adresscountry").val());
                setadresslastname(querySnapshot.child("adresslastname").val());
                setadressline1(querySnapshot.child("adressline1").val());
                setadressname(querySnapshot.child("adressname").val());
                setadressphonenumber(querySnapshot.child("adressphonenumber").val());
                setadresspostcode(querySnapshot.child("adresspostcode").val());

                document.getElementById("productlink").value = querySnapshot.child("productlink").val();
                document.getElementById("productname").value = querySnapshot.child("productname").val();
                document.getElementById("productcode").value = querySnapshot.child("productcode").val();
                document.getElementById("productsize").value = querySnapshot.child("productsize").val();
                document.getElementById("productcolor").value = querySnapshot.child("productcolor").val();
                document.getElementById("productquantity").value = querySnapshot.child("productquantity").val();
                document.getElementById("productpriceintl").value = querySnapshot.child("productpriceintl").val();
                document.getElementById("productervestafee").placeholder = querySnapshot.child("productervestafee").val();
                document.getElementById("producttotalcost").placeholder = querySnapshot.child("producttotalcost").val();
                document.getElementById("productcomment").value = querySnapshot.child("productcomment").val();
                
                document.getElementById("adressline1").value = querySnapshot.child("adressline1").val();
                document.getElementById("adresscity").value = querySnapshot.child("adresscity").val();
                document.getElementById("adresspostcode").value = querySnapshot.child("adresspostcode").val();
                document.getElementById("adresscountry").value = querySnapshot.child("adresscountry").val();
                document.getElementById("adressphonenumber").value = querySnapshot.child("adressphonenumber").val();
                document.getElementById("adressname").value = querySnapshot.child("adressname").val();
                document.getElementById("adresslastname").value = querySnapshot.child("adresslastname").val();
                document.getElementById("selecter").value = querySnapshot.child("material").val();

            })
        }else {
            
        }
    });
    
    function getDate(){
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
    
        today = mm + '/' + dd + '/' + yyyy;
        return today;
    }
    function validateproductinputs() {
      var outputmsg = "true";
      if(document.getElementById("productlink").value.length<5){
        outputmsg = "Product link is too short. Please share page link of the product, not only brand website.\n"
        document.getElementById("productlink").style.border="2px solid red";
      }    
      if(!document.getElementById("productlink").value.includes(".")){
        outputmsg = outputmsg+"Product link is invalid. Please share page link of the product, not only brand website.\n"
        document.getElementById("productlink").style.border="2px solid red";
      } 
      if(document.getElementById("productname").value.length<2){
        outputmsg = outputmsg+"Product name is too short.\n"
        document.getElementById("productname").style.border="2px solid red";
      }
      if(document.getElementById("productsize").value.length<1){
        outputmsg = outputmsg+"Product size can't be empty. If size is not valid, just enter X.\n"
        document.getElementById("productsize").style.border="2px solid red";
      }
      if(document.getElementById("productcolor").value.length<1){
        outputmsg = outputmsg+"Product color can't be empty. If color is not valid, just enter X.\n"
        document.getElementById("productcolor").style.border="2px solid red";
      }
      if(document.getElementById("productpriceintl").value.length<1){
        outputmsg = outputmsg+"Price in turkish Liras can't be empty. Please enter valid value. Such as: 300 TL\n"
        document.getElementById("productpriceintl").style.border="2px solid red";
      }
      if(document.getElementById("adressline1").value.length<8){
        outputmsg = outputmsg+"Adress Line 1 is too short. Are you sure you enter correct adresss?\n"
        document.getElementById("adressline1").style.border="2px solid red";
      }
      if(document.getElementById("adresscity").value.length<2){
        outputmsg = outputmsg+"Adress City is too short. Are you sure you enter correct city name?\n"
        document.getElementById("adresscity").style.border="2px solid red";
      }
      if(document.getElementById("adresspostcode").value.length<2){
        outputmsg = outputmsg+"Adress Post Code is too short. Are you sure you enter correct Post Code? Example post codes; 010016,050000\n"
        document.getElementById("adresspostcode").style.border="2px solid red";
      }
      if(document.getElementById("adressname").value.length<2){
        outputmsg = outputmsg+"Name of the receiver is too short.\n"
        document.getElementById("adressname").style.border="2px solid red";
      }
      if(document.getElementById("adresslastname").value.length<2){
        outputmsg = outputmsg+"Last name of the receiver is too short.\n"
        document.getElementById("adresslastname").style.border="2px solid red";
      }
      //alert("phn: "+parseInt(document.getElementById("adressphonenumber").replaceAll("(","").replaceAll(")","").replaceAll("+","").replaceAll("-","").replaceAll(" ", "")));
      if( parseInt(document.getElementById("adressphonenumber").value.replaceAll("(","").replaceAll(")","").replaceAll("+","").replaceAll("-","").replaceAll(" ", ""))<70000000000 || parseInt(document.getElementById("adressphonenumber").value.replaceAll("(","").replaceAll(")","").replaceAll("+","").replaceAll("-","").replaceAll(" ", ""))>79999000000){
        outputmsg = outputmsg+"Phone number is invalid.\n"
        document.getElementById("adressphonenumber").style.border="2px solid red";
      }
      if(document.getElementById("selecter").value === "chooseacategory"){
        outputmsg = outputmsg+"Choose a category.\n"
        document.getElementById("selecter").style.border="2px solid red";
      }
      if(outputmsg == "true")
      return true;
      else {
        alert(outputmsg);
        setDisable(false);
        return false;}
    }
    const [signedin, setSignedIn] = useState(false);
    const changesignstatus = (mybool) => setSignedIn(mybool);
    const [isdisabled, setDisable] = useState(false);
    const placeorder = () =>{

        setDisable(true);
        if(signedin){
                  if(validateproductinputs()){
          db.ref("/orders/"+IdFromURL).set({
              productlink: document.getElementById("productlink").value,
              productname: document.getElementById("productname").value,
              productcode: document.getElementById("productcode").value,
              productsize: document.getElementById("productsize").value,
              productcolor: document.getElementById("productcolor").value,
              productquantity: document.getElementById("productquantity").value,
              productpriceintl: document.getElementById("productpriceintl").value,
              productervestafee: document.getElementById("productervestafee").placeholder,
              producttotalcost: document.getElementById("producttotalcost").placeholder,
              productcomment: document.getElementById("productcomment").value,
              useruid: useruid,
              usermail: usermail,
              status: "waiting for payment",
              date: "edited: "+getDate(),
              adressline1: document.getElementById("adressline1").value,
              adresscity: document.getElementById("adresscity").value,
              adresspostcode: document.getElementById("adresspostcode").value,
              adresscountry: document.getElementById("adresscountry").value,
              adressphonenumber: document.getElementById("adressphonenumber").value,
              adressname: document.getElementById("adressname").value,
              adresslastname: document.getElementById("adresslastname").value,
              material: document.getElementById("selecter").value,
            })
            .then(() => {
                    console.log("Data set.")
                    db.ref("/users/"+IdFromURL2+"/orders/"+IdFromURL).set({
                      productlink: document.getElementById("productlink").value,
                      productname: document.getElementById("productname").value,
                      productcode: document.getElementById("productcode").value,
                      productsize: document.getElementById("productsize").value,
                      productcolor: document.getElementById("productcolor").value,
                      productquantity: document.getElementById("productquantity").value,
                      productpriceintl: document.getElementById("productpriceintl").value,
                      productervestafee: document.getElementById("productervestafee").placeholder,
                      producttotalcost: document.getElementById("producttotalcost").placeholder,
                      productcomment: document.getElementById("productcomment").value,
                      useruid: useruid,
                      usermail: usermail,
                      status: "waiting for payment",
                      date: "edited: "+getDate(),
                      adressline1: document.getElementById("adressline1").value,
                      adresscity: document.getElementById("adresscity").value,
                      adresspostcode: document.getElementById("adresspostcode").value,
                      adresscountry: document.getElementById("adresscountry").value,
                      adressphonenumber: document.getElementById("adressphonenumber").value,
                      adressname: document.getElementById("adressname").value,
                      adresslastname: document.getElementById("adresslastname").value,
                      material: document.getElementById("selecter").value,
                    })
                    .then(() => {
                      console.log("order saved on profile")
                      
                            setDisable(false);
                            window.location.href = "./post-order?uid="+JSON.stringify(IdFromURL).replaceAll("\"","");
                            console.log("adress saved on profile")
                          
                    })
            })}
          }else{
              
          }
      
      };

  return (
    <View style={styles.container1}>
        
      <View style={styles.container2}>
            <h1 > Edit your order: {IdFromURL} <i class="fa-solid fa-pen"></i></h1>
            
            <div className="form2" >
            
                <div className="form-body">
              <div className="username">
                  <Text className="" >Product link*</Text>
                  <input className="form__input" type="text" id="productlink" placeholder="https://www.dyson.com.tr/dyson-airwrap-styler-complete-long-iron-fuchsia"/>
                  <Text className="form__smalltext">Choose products on the Turkey localization of the website of the brand you need. Such as; www.massimodutti.com/tr,
                   www.dyson.com.tr etc. See <Link className="form__label" to='/products'>brands</Link> page to learn all available brands.</Text><br/>
              </div>
              <div className="form-body2">
                <div>
                    <Text className="" >Product name*</Text><br/>
                    <input type="text" name="" id="productname"  className="form__halfinput" placeholder="For example: Shirt"/>
                </div>
                <div>
                    <Text className="" >Product code</Text><br/>
                    <input  type="text" name="" id="productcode"  className="form__halfinput" placeholder="For example: 1234/567"/>
                </div>
                <Text className="form__smalltext" ><br/>Name of the product as on the brand website.</Text><br/>
              </div>
              <div/>
              <div div className="form-body2">
                  <div className="password">
                    <Text className="" >Size*</Text><br/>
                    <input  type="text" id="productsize"  className="form__halfinput" placeholder="For example: L or 32"/>
                  </div>
                  <div className="password">
                    <Text className="" >Color*</Text><br/>
                    <input  className="form__halfinput" type="text"  id="productcolor" placeholder="For example: Green"/>
                  </div>
                  <div className="confirm-password">
                    <Text className="" >Quantity*</Text><br/>
                    <input  className="form__halfinput" onChange={handleInputTextChange} min="1" type="number" id="productquantity" placeholder="1"/>
                  </div>
              </div>
              <Text className="" >{t("productcategory2")}*</Text>
              <select id="selecter" onChange={handleInputTextChange} className="form__halfinput" style={{marginLeft: 5, width:200,height:45}} name="cars">
                <option value="chooseacategory">{t("chooseacategory")}</option>
                <option value="nonmetal">{t("nonmetal")}</option>
                <option value="metal">{t("metal")}</option>
              </select>
              <div div className="form-body2">
                  <div className="password">
                    <Text className="" >Price in Turkish Liras*</Text><br/>
                    <input  type="text" id="productpriceintl" onChange={handleInputTextChange}  className="form__halfinput" placeholder="For example: 99.90 TL"/>
                    <Text className="form__smalltext" ><br/>1 Turkish Lira = 30.7 Kazakh Tenge.</Text><br/>
                  </div>
                  <div className="password">
                    <Text className="" >ERVESTA service fee</Text><br/>
                    <input  disabled className="form__halfinput" type="text"  id="productervestafee" placeholder="20% fee"/>
                  </div>
                  <div className="confirm-password">
                    <Text className="" >Total cost in KZT</Text><br/>
                    <input  disabled className="form__halfinput" type="number" id="producttotalcost" placeholder="0 KZT" />
                  </div>
              </div>
              <div div className="form-body">
                  <div className="password">
                    <Text  >Comments</Text><br/>
                    <input  type="text" id="productcomment"  className="form__input" placeholder="Tell us if you need anything special"/>
                  </div>
              </div>
              <br/>
              <text className="form__titellabel" for="firstName">Adress and Reciever Information</text>
              <Text className="" >Make sure your adress is correct. Type name of person who will recieve the package.</Text>
              
              <div div className="form-body">
                  <div className="">
                    <Text className="" >Adress Line 1*</Text><br/>
                    <input type="text" id="adressline1"  className="form__input" placeholder="Your adress formal description"/>
                  </div>
                  
              </div>
              <div div className="form-body2">
                  <div >
                    <Text className="" >City*</Text><br/>
                    <input  type="text" id="adresscity"  className="form__halfinput" placeholder="Almaty, Nur-Sultan etc."/>
                  </div>
                  <div >
                    <Text className="" >Post code*</Text><br/>
                    <input type="text" id="adresspostcode"  className="form__halfinput" placeholder="For example: 050000"/>
                  </div>
                  <div >
                    <Text className="" >Country*</Text><br/>
                    <input disabled type="text" id="adresscountry"  className="form__halfinput" placeholder="KAZAKHSTAN"/>
                  </div>
              </div>
              <div div className="form-body2">
                  <div >
                    <Text className="" >Phone Number*</Text><br/>
                    <MaskInput  id="adressphonenumber" className="form__halfinput" alwaysShowMask mask={'+7 (000) 000 - 0000'} showMask maskChar="_" />
                  </div>
                  <div >
                    <Text className="" >First Name of Reciever*</Text><br/>
                    <input  type="text" id="adressname"  className="form__halfinput" placeholder="Name of receiver"/>
                  </div>
                  <div >
                    <Text className="" >Last Name of Reciever*</Text><br/>
                    <input  type="text" id="adresslastname"  className="form__halfinput" placeholder="Surname of receiver"/>
                  </div>
              </div>
              <div div className="form-body2">
                <CheckBox style={{ width: 24, height: 24 }} value={isSelected} onValueChange={setSelection} ></CheckBox><Text className="" >Save my adress</Text>
              </div>
              
          </div>
          <View >
              <button disabled={isdisabled} className= "btn--disabled" onClick={placeorder} style={styles.buttoncontainer} >SAVE CHANGES <i class="fa-solid fa-floppy-disk"></i></button> 
              <button className= "btn--disabled" style={styles.smallerbuttoncontainer} onClick={()=> window.location.href = "./profile"}>CANCEL CHANGES <i class="fa-solid fa-xmark"></i></button>   
          </View>
            
              
        </div>


      </View>
    </View>
  );
}

const styles = {
    container1: {
      marginTop: 55,
      flexDirection: "row",
      textAlign: 'center',
      flex: 1,
    },
    container2: {
      marginTop: 10,
      flexDirection: "column",
      textAlign: 'center',
      flex: 1,
    },
    container: {
      marginTop: 5,
      flexDirection: "row",
      marginBottom: 5,
      flex: 1
    },
    buttoncontainer: {
        marginRight: "30%",
        marginLeft: "30%",
        textAlign: 'center',
        marginTop: 5,
    },
    smallerbuttoncontainer: {
        marginRight: "35%",
        marginLeft: "35%",
        textAlign: 'center',
        marginTop: 5,
        marginBottom:20
    },
    imagecontainer: {
        width: 120,
        height: 120,
        textAlign: 'center',
        marginBottom: 5,
    },
    rowcontainer2: {
        width: "100%",
        textAlign: 'center',
        flexDirection: "row",
        justifyContent: 'center',
        flex:1,
    },
    infobarcontainer: {
      textAlign: 'left',
      marginBottom: 5,
      flex:5,
    },
    statuscontainer: {
      textAlign: 'center',
      marginBottom: 0,
      marginLeft: 5,
      flex:1.2,
    },
    actionscontainer: {
      textAlign: 'center',
      marginBottom: 5,
      marginLeft: 5,
      flex:1.2,
      justifyContent: 'space-between',
    },
    rowcontainer: {width: "95%",
      textAlign: 'left',
      flexDirection: "row",
      marginBottom: 5,
      justifyContent: 'space-between',
      flex:1.2,
    }
  }

export default PostOrder;

