import React from 'react';
import {Text,Image,View,CheckBox} from "react-native";
import '../languages/i18n'; 
import {useTranslation} from 'react-i18next';
import './pages/pagescss/signup.css';

function HowItWorkscomp() {
    
  const {t, i18n} = useTranslation();
  return (
 
    <>

      <View style={{marginLeft: "10%",marginRight:"10%", textAlign:"center",flexDirection:"column",flex:1,display:"flex",justifyContent:"center"}}>
            <text className="form__shemeofworktext">{t("schemeofwork")}</text>
            <View style={{flexWrap:"wrap",marginTop:"5%", marginLeft:"4%",marginRight:"4%", textAlign:"top",flexDirection:"row",flex:1,display:"flex",justifyContent:"top"}}>
                <View style={{flexWrap:"wrap",marginTop:"2%", marginLeft:"10%",marginRight:"4%", textAlign:"top",flexDirection:"column",flex:1,display:"flex",justifyContent:"top"}}>
                    <text style={{marginLeft:"-40px", fontSize:"30px", fontFamily:"Inter",textAlign:"left",}}><i class="fa-solid fa-circle-check"></i> 1</text>
                    <text className="form__shemeofworktextsmaller">{t("schemeofworktext1")}</text>
                </View>
                <View style={{flexWrap:"wrap",marginTop:"2%", marginLeft:"4%",marginRight:"4%", textAlign:"top",flexDirection:"column",flex:1,display:"flex",justifyContent:"top"}}>
                    <text style={{marginLeft:"-40px", fontSize:"30px", fontFamily:"Inter",textAlign:"left",}}><i class="fa-solid fa-circle-check"></i> 2</text>
                    <text className="form__shemeofworktextsmaller">{t("schemeofworktext2")}</text>
                </View>
            </View>
            <View style={{flexWrap:"wrap",marginTop:"5%", marginLeft:"10%",marginRight:"4%", textAlign:"top",flexDirection:"row",flex:1,display:"flex",justifyContent:"top"}}>
                <View style={{flexWrap:"wrap",marginTop:"2%", marginLeft:"4%",marginRight:"4%", textAlign:"top",flexDirection:"column",flex:1,display:"flex",justifyContent:"top"}}>
                    <text style={{marginLeft:"-40px", fontSize:"30px", fontFamily:"Inter",textAlign:"left",}}><i class="fa-solid fa-circle-check"></i> 3</text>
                    <text className="form__shemeofworktextsmaller">{t("schemeofworktext3")}</text>
                </View>
                <View style={{flexWrap:"wrap",marginTop:"2%", marginLeft:"4%",marginRight:"4%", textAlign:"top",flexDirection:"column",flex:1,display:"flex",justifyContent:"top"}}>
                    <text style={{marginLeft:"-40px", fontSize:"30px", fontFamily:"Inter",textAlign:"left",}}><i class="fa-solid fa-circle-check"></i> 4</text>
                    <text className="form__shemeofworktextsmaller">{t("schemeofworktext4")}</text>
                </View>
            </View>
       </View>

</>
            
  )}
  
    
  export default HowItWorkscomp;