import React, { useState } from 'react';
import "../../App.css";
import { View, Text,Image,CheckBox} from 'react-native';
import Cards from "../Cards";
import '../../languages/i18n'; 
import {useTranslation} from 'react-i18next';
import Footer from "../Footer";
import { Button } from "../Button";
import { Link } from 'react-router-dom';
import { db,auth,user } from '../../firebase';

function AboutUs() {
 
    
const {t, i18n} = useTranslation();
const [signedin, setSignedIn] = useState(false);
    const changesignstatus = (mybool) => setSignedIn(mybool);
    const [isdisabled, setDisable] = useState(false);
    const [uidtext, setuidtext] = useState("uid");
    const changeuidtext = (text) => setuidtext(text);

    auth.onAuthStateChanged(function(user) {
      if (user) {
      //changesignstatus(true);
      changeuidtext(user.uid)
      changesignstatus(true);
      //changeshowlogin(false);

      }else {
          
      }
  });


  function validateemail(text) {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      return false;
    }
    else {return true;
    }
  }
  function validateproductinputs() {
    var outputmsg = "true";
    if(document.getElementById("topic").value.length<2){
      outputmsg = "Topic is too short.\n"
      document.getElementById("topic").style.border="2px solid red";
    }    
    if(document.getElementById("topic").value.length>1000){
      outputmsg = "Topic is too long. Maximum 1000 char allowed.\n"
      document.getElementById("topic").style.border="2px solid red";
    } 
    if(document.getElementById("message").value.length<2){
      outputmsg = "Message is too short.\n"
      document.getElementById("message").style.border="2px solid red";
    } 
    if(document.getElementById("message").value.length>2000){
      outputmsg = "Message is too long. Maximum 2000 char allowed.\n"
      document.getElementById("message").style.border="2px solid red";
    }
    if(!validateemail(document.getElementById("emailbox").value)){
      outputmsg = "Email format is not correct.\n"
      document.getElementById("emailbox").style.border="2px solid red";
    } 
    if(outputmsg == "true")
    return true;
    else {
      alert(outputmsg);
      setDisable(false);
      return false;}
  }

    function sendmessage () {

      setDisable(true);

      if(validateproductinputs()){
        db.ref("/gethelp/"+document.getElementById("emailbox").value.replaceAll("@","-at-").replaceAll(".","-dot-")).set({
            email: document.getElementById("emailbox").value.replaceAll("@","-at-").replaceAll(".","-dot-"),
            topic: document.getElementById("topic").value,
            message: document.getElementById("message").value,
          })
          .then(() => {
            setDisable(false);
            alert(t("Succesful"));
            window.location.href = "./";
          }).catch(error =>{alert("Error: " + error)})}
          
          else{
            setDisable(false);
          }
        
    };
  return (
    <>

        <View style={{flexWrap:"wrap",marginTop:"5%", marginLeft:"10%",marginRight:"10%", textAlign:"center",flexDirection:"row",flex:1,display:"flex",justifyContent:"center"}}>
            <View style={{width:"80%",minWidth:"150px", textAlign:"center",flexDirection:"column",flex:1,display:"flex",justifyContent:"center"}}>
                <text style={{fontStyle: "bold", fontSize:"50px", fontFamily:"Inter"}}>{t("contactus")}</text><br/><br/>
                <text style={{fontSize:"20px", textAlign:"center",}}>info@ervesta.com</text><br/>
                <text style={{fontSize:"20px", textAlign:"center",}}>Eryaman, 285. Sk. No:3, 06824 Etimesgut/Ankara TURKEY</text><br/>
                
                
                <text style={{fontSize:"20px", textAlign:"center",}}>{t("contactinfo")}</text><br/>
                
           </View>
          
           <div className="form">
        <text className="form__titellabel" for="firstName">{t("contactus")}</text><br />
          <div className="form-body">
              <div className="email">
                  <input  type="email" id="emailbox" className="form__input" placeholder={t("email")}/>
              </div>
              <div className="password">
                <input className="form__input" type="text"  id="topic" placeholder={t("topic")}/>
              </div>
              <div className="password">
                <textarea cols="40" rows="5"  className="form__input" style={{height:"150px"}} type="text"  id="message" placeholder={t("yourmessage")}/>
              </div>
          </div>
          <div class="footer">
              <Button buttonStyle= "btn--outline2" onClick={() => {sendmessage()}}>{t("sendmessage")}{" >"}</Button>
          </div>
      </div>    

        </View>
        <br/><br/>
        <div style={{padding:"50px",textAlign:"center"}}>
            <text className="form__logotext" translate="no" to="/" >ERVESTA</text><br/>
            <text className="form__logotextslogan" translate="no" to="/"  >shop smart</text>
       </div>
      <Footer/>

    </>
  );
}

export default AboutUs;