//import '../../App.css';
import { db } from '../../firebase';
import { auth } from '../../firebase';
import './pagescss/signup.css';
import { Button } from "../Button";
import React,{useState, useEffect,Suspense } from 'react';
import { SafeAreaView ,StyleSheet, TextInput ,Text,Image,Switch,View,FlatList} from "react-native";
import { Link } from 'react-router-dom';
import Footer from "../Footer";
import { List} from 'native-base';
import ListItemAdmin from "../ListItemAdmin";
import AdressList from "../AdressList";

function SYS() {

  const [welcomeMessage, setwelcomeMessage] = useState("Welcome!");
  const changeMessage = (text) => setwelcomeMessage(text);

  const [uidtext, setuidtext] = useState("uid");
  const changeuidtext = (text) => setuidtext(text);
  
  const [isadmin, setisadmintext] = useState(false);
  const changeisadmintext = (text) => setisadmintext(text);

  const [numwfp, setnumwfp] = useState(0);
  const [numwfs, setnumwfs] = useState(0);
  const [numshp, setnumshp] = useState(0);
  const [numdlv, setnumdlv] = useState(0);
  const [numoth, setnumoth] = useState(0);

  const [registerdatetext, setregisterdatetext] = useState("nodate");
  const changeregisterdatetext = (text) => setregisterdatetext(text);
  
  const [balancetext, setbalacetext] = useState("0");
  const changebalancetext = (text) => setbalacetext(text);

  const [showstatus, setshowstatus] = useState("waiting for payment");
  const changeshowstatus = (text) => setshowstatus(text);

  auth.onAuthStateChanged(function(user) {
      if (user) {
        //changesignstatus(true);
      
        changeMessage(""+user.email+"")
        changeuidtext(user.uid)
        db.ref("/users/"+user.uid)
        .once("value")
        .then(snapshot => {
          // changenametext(snapshot.child("name").val());
          changeregisterdatetext(snapshot.child("registerdate").val());
          changebalancetext(snapshot.child("balance").val());
          //window.location.href = "./profile";
        });

        db.ref("orders/").once("value").then((querySnapshot) => {
             
            var i = 0;var i2= 0;var i3= 0;var i4= 0;var i5= 0;var i6= 0;
            querySnapshot.forEach(element => {
                var data = element.val();
                i++;
                if(element.child("status").val() === "waiting for payment"){
                    i2++;
                }
                else if(element.child("status").val() === "waiting for supply"){
                    i3++;
                }
                else if(element.child("status").val() === "shipped"){
                    i4++;
                }
                else if(element.child("status").val() === "delivered"){
                    i5++;
                }
                else{
                  i6++;
                }
            });

            setnumwfp(i2);
            setnumwfs(i3);
            setnumshp(i4);
            setnumdlv(i5);
            setnumoth(i6);

            if(i+i2+i3+i4+i5+i6>0){
              changeisadmintext(true);
            }
        })
        
      }else {
          //changesignstatus(false);
          changeMessage("Welcome!");
      }
  });
  /*
  function getListView(requestedstatus){
    return (<ListItemAdmin statusadmin={requestedstatus}/>)
  }*/
  const _onPressButton = () =>{
    alert('Modal has been closed.');
    db.ref("/users/123").set({
        name: "zhard elace",
        age: 11,
      })
      .then(() => console.log("Data set."))};
      
    const signoutfun = () =>{
      auth.signOut().then(function() {
        console.log('Signed Out');
        window.location.href = "./";
      }, function(error) {
        console.error('Sign Out Error', error);
      });
    };

      const handleInputTextChange = () =>{
                 
            document.getElementById("ervestafee").placeholder = parseInt(document.getElementById("quantity").value)*parseInt(document.getElementById("price").value)*0.2*30+" KZT";
            document.getElementById("totalprice").placeholder=
            parseInt(document.getElementById("quantity").value)*parseInt(document.getElementById("price").value)*30
            +parseInt(document.getElementById("ervestafee").placeholder) +" KZT";
            
          
        };
        /*
        const [data, setmyData]=useState([])
        useEffect(()=>{
          const data = db.ref("users/"+uidtext+"/orders/");
          data.on("value", datasnap =>{
            let data = [];
            datasnap.forEach((childsnap) => {
              let val = childsnap.val();
              val["$key"] = childsnap.key;
              data.push(val);
            })
            setmyData(data);
          })
        },[]);*/
        useEffect(()=>{
          <ListItemAdmin statusadmin={showstatus} issafemode={isEnabled}/>
        },[numwfp,numwfs,numshp,numdlv,numoth]);

        const [isEnabled, setIsEnabled] = useState(true);
        const toggleSwitch = () => setIsEnabled(previousState => !previousState);
        
        
        

    return(
      <>
      {isadmin && <div className="topbody">
      <text className="form__titellabel" for="firstName">ADMIN PANEL</text><br />
      <text className="form__titellabel" for="firstName">{welcomeMessage}</text>
            
          <div className="form">
            <text className="form__titellabel" for="firstName">All Orders <i class="fa-solid fa-receipt"></i></text><br /> 
            {<Button id="userbtn2" buttonStyle= {showstatus == "waiting for payment" ? "btn--outline3": "btn--outline2" }   onClick={()=>changeshowstatus("waiting for payment")} >Waiting for payment({numwfp})</Button>}
            {<Button id="userbtn2" buttonStyle= {showstatus == "waiting for supply" ? "btn--outline3": "btn--outline2"}  onClick={()=>changeshowstatus("waiting for supply")} >Waiting for supply({numwfs})</Button>}
            {<Button id="userbtn2" buttonStyle= {showstatus == "shipped" ? "btn--outline3": "btn--outline2"}  onClick={()=>changeshowstatus("shipped")} >Shipped({numshp})</Button>}
            {<Button id="userbtn2" buttonStyle= {showstatus == "delivered" ? "btn--outline3": "btn--outline2"}  onClick={()=>changeshowstatus("delivered")} >Delivered({numdlv})</Button>}
            {<Button id="userbtn2" buttonStyle= {showstatus == "others" ? "btn--outline3": "btn--outline2"}  onClick={()=>changeshowstatus("others")} >Others({numoth})</Button>}
            <br/>
            
            <View style={{flex: 1,border: "1px solid",backgroundColor:"#31313107" ,padding: "5px", alignItems: 'left',marginTop:10, flexDirection: 'row'}}>
                  <text style={{}} className="form__titellabel" for="firstName">Settings: </text>
                  <text style={{marginTop:10,marginLeft:15,}} className="form__text1small" for="firstName">SAFE MODE</text>
                  <Switch style={{width:40,left:10,top:5}}
                    trackColor={{ false: "#FF7979", true: "#00CF6F" }}
                    thumbColor={isEnabled ? "#008145" : "#910000"}
                    ios_backgroundColor="#3e3e3e"
                    onValueChange={toggleSwitch}
                    value={isEnabled}
                />
            </View>
              <ListItemAdmin statusadmin={showstatus} issafemode={isEnabled}/>
      </div>   
      <Footer/>
      </div>  }
      </>
    )       
}

export default  SYS;
