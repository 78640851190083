import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import '../languages/i18n'; 
import {useTranslation} from 'react-i18next';

function Footer() {
  const {t, i18n} = useTranslation();
  return (
    <div className='footer-container'>
      
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          {t("footertitle")}
        </p>
        <p className='footer-subscription-text'>
        {t("youcanunsubscribe")}
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div>
      </section>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>

          <div class='footer-link-items'>
            <h2>{t("aboutus")}</h2>
            <Link to='/how-it-works'>{t("howitworks")}</Link>
            <Link to='/'>{t("brands")}</Link>
            <Link to='/'>{t("careers")}</Link>
          </div>
          <div class='footer-link-items'>
            <h2>{t("contactus")}</h2>
            <Link to='/contact-us'>{t("contact")}</Link>
            <Link to='/'>{t("support")}</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>{t("termsofservice")}</h2>
            <Link to='/message-viewer?text=cancellationpolicytext'>{t("cancellationpolicy")}</Link>
            <Link to='/message-viewer?text=privacypolicytext'>{t("privacypolicy")}</Link>
            <Link to='/message-viewer?text=distancesalestext'>{t("distancesales")}</Link>
            <Link to='/message-viewer?text=paymentanddeliverytext'>{t("paymentanddelivery")}</Link>
          </div>
          <div class='footer-link-items'>
            <h2>{t("socialmedia")}</h2>
            <Link to='https://instagram.com/ervesta.shop'>Instagram</Link>
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
        <div class='footer-logo'>
          <Link to="/" className="navbar-logowhite">
              <img style={{ height:100,width:100,marginBottom:15 }} src="./images/cardlogo.jpeg" ></img>        
                    
            </Link>
          </div>
          <div class='footer-logo'>
          <Link to="/" className="navbar-logowhite">
                    ERVESTA
                    
            </Link>
          </div>
          <small class='website-rights'>ERVESTA © 2020</small>
          <div class='social-icons'>
            
            <Link
              class='social-icon-link instagram'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
