
import "./App.css";
import React from "react";
import Navbar from "./components/Navbar";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./components/pages/Home";
import Services from "./components/pages/Services";
import Products from "./components/pages/Products";
import SYS from "./components/pages/SYS";
import SignUp from "./components/pages/SignUp";
import AboutUs from "./components/pages/AboutUs";
import ContactUs from "./components/pages/ContactUs";
import Profile from "./components/pages/Profile";
import EditOrder from "./components/pages/EditOrder";
import EditOrderAdmin from "./components/pages/EditOrderAdmin";
import ListItem from "./components/ListItem";
import HowItWorks from "./components/pages/HowItWorks";
import MessageViewer from "./components/pages/MessageViewer";
import PostOrder from "./components/pages/PostOrder";
import GetHelp from "./components/GetHelp";

function App() {
  
  return (
    <Router>
     <Navbar/>
        <Routes> 
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/products" element={<Products />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/list-item" element={<ListItem />} />
            <Route path="/post-order" element={<PostOrder />} />
            <Route path="/edit-order" element={<EditOrder />} />
            <Route path="/get-help" element={<GetHelp />} />
            <Route path="/edit-order-admin" element={<EditOrderAdmin />} />
            <Route path="/how-it-works" element={<HowItWorks />} />
            <Route path="/message-viewer" element={<MessageViewer />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/sys" element={<SYS />} />
        </Routes>
    </Router>

  );
}

export default App;
