

// Import Firestore database
import { db,auth,user } from '../firebase';
import React, { useState,useEffect } from 'react';
import './pages/pagescss/signup.css';
import { Button } from "./Button";
import { View, Text,Image,CheckBox} from 'react-native';
import { Link } from 'react-router-dom';
import MaskInput from 'react-maskinput';
//import './read.css';

  


const ListItemAdmin = ({statusadmin,issafemode}) => {
  
    const [info , setInfo] = useState([]);
  
    const [uidtext, setuidtext] = useState("uid");
    const changeuidtext = (text) => setuidtext(text);

    const [cars, setCars] = useState([]);
      
    
    const [iseditmode , setiseditmode] = useState(false);
    const [isSelected, setSelection] = useState(true);
    

    // Start the fetch operation as soon as
    // the page loads
    /*
    window.addEventListener('load', () => {
        Fetchdata();
      });*/

    // Fetch the required data using the get() method
    const Fetchdata = ()=>{
        auth.onAuthStateChanged(function(user) {
            if (user) {
            //changesignstatus(true);
            
            changeuidtext(user.uid)
            
            db.ref("orders/").once("value").then((querySnapshot) => {
             
            changeuidtext(user.uid);
            // Loop through the data and store
            // it in array to display
            var i = 0;
            const newCar = [];
            querySnapshot.forEach(element => {
                if(querySnapshot.child(element.key).child("status").val() === statusadmin){

                    var data = element.val();
                    newCar[i] = element.key; 
                    setInfo(arr => [...arr , data]);
                    i++;
                }
            });
            setCars(newCar);
        })
            }else {
                //changesignstatus(false);
            }
        });
    }
    useEffect(()=> {
        setInfo([]);
        Fetchdata();
    }, [statusadmin,issafemode]);
    // Display the result on the page
    return (
        <div>
          
        {
            info.map((data,index) => (
            <Frame  
                ordernumber={cars[index]} 
                productlink={data.productlink} 
                productname={data.productname} 
                productsize={data.productsize} 
                productcolor={data.productcolor} 
                productcode={data.productcode} 
                productquantity={data.productquantity} 
                productpriceintl={data.productpriceintl} 
                productervestafee={data.productervestafee} 
                producttotalcost={data.producttotalcost} 
                productcomment={data.productcomment}
                status={data.status}
                date={data.date}
                useruid={data.useruid} 
                usermail={data.usermail} 
                adressline1={data.adressline1} 
                adressline2={data.adressline2} 
                adresscity={data.adresscity} 
                adresspostcode={data.adresspostcode} 
                adresscountry={data.adresscountry} 
                adressphonenumber={data.adressphonenumber}
                adressname={data.adressname} 
                adresslastname={data.adresslastname}
                issafemodemyframe = {issafemode}   />
            ))
        }
        </div>
  
    );
}
                 
const handleInputTextChange = () =>{
    document.getElementById("productervestafee").placeholder = (parseInt(document.getElementById("productquantity").value)*parseInt(document.getElementById("productpriceintl").value)*0.2*30).toFixed(2)+" KZT";
    document.getElementById("producttotalcost").placeholder=
    parseInt(document.getElementById("productquantity").value)*parseInt(document.getElementById("productpriceintl").value)*30
    +parseInt(document.getElementById("productervestafee").placeholder) +" KZT";
    
};

// Define how each display entry will be structured
const Frame = ({ordernumber, productlink,productname, productsize,
    productcolor,productcode,productquantity,productpriceintl,useruid,usermail,
    productervestafee,producttotalcost,productcomment,status,date,adressline1,
    adressline2,adresscity,adresspostcode,adresscountry,adressphonenumber,adressname,adresslastname,issafemodemyframe}) => {
        
    const [cancelshow, setcancelshow] = useState(false);
    function deleteorder(){
        setcancelshow(false);
        db.ref("users/"+useruid+"/orders/").child(ordernumber).remove().then(()=>{
            db.ref("orders/").child(ordernumber).remove().then(()=>{
                window.location.href = "./sys";
            });
        });
    };
    function approvepayment(){
        db.ref("users/"+useruid+"/orders/"+ordernumber).update({
            status: "waiting for supply"
        }).then(()=>{
            db.ref("orders/"+ordernumber).update({
                status: "waiting for supply"
            }).then(()=>{
                window.location.href = "./sys";
            });
        });
    };
    function markshipped(){
        db.ref("users/"+useruid+"/orders/"+ordernumber).update({
            status: "shipped"
        }).then(()=>{
            db.ref("orders/"+ordernumber).update({
                status: "shipped"
            }).then(()=>{
                window.location.href = "./sys";
            });
        });
    };
    function markdelivered(){
        db.ref("users/"+useruid+"/orders/"+ordernumber).update({
            status: "delivered"
        }).then(()=>{
            db.ref("orders/"+ordernumber).update({
                status: "delivered"
            }).then(()=>{
                window.location.href = "./sys";
            });
        });
    };
    return (
        <div className="form2" >
            <View style={styles.container}>
            <View style={styles.infobarcontainer} >
                    <View style={styles.rowcontainer}>
                        <text style={styles.a}><Text>Order number: </Text>{ordernumber}</text>
                        <text style={styles.a}><Text>OrderDate: </Text>{date}</text>
                    </View>
                    <text ><Text>Product link: </Text>{productlink}</text>
                    <View style={styles.rowcontainer}>
                        <text style={styles.a}><Text>Product name: </Text>{productname}</text>
                        <text style={styles.a}><Text>Product code: </Text>{productcode}</text>
                    </View>
                    <View style={styles.rowcontainer}>
                        <text style={styles.a}><Text>Size: </Text>{productsize}</text>
                        <text style={styles.a}><Text>Color: </Text>{productcolor}</text>
                        <text style={styles.a}><Text>Quantity: </Text>{productquantity}</text>
                    </View>
                    <View style={styles.rowcontainer}>
                        <text style={styles.a}><Text>Price in Turkish Liras: </Text>{productpriceintl}</text>
                        <text style={styles.a}><Text>ERVESTA service fee: </Text>{productervestafee}</text>
                        <text style={styles.a}><Text>Total Cost: </Text>{producttotalcost}</text>
                    </View>    
                    
                    <text ><Text>Adress: </Text>{adressline1}</text>
                    <text >{adressline2}</text>
                    <View style={styles.rowcontainer}>
                        <text style={styles.a} className="" ><Text>City: </Text>{adresscity}</text>
                        <text style={styles.a} className="" ><Text>Post code: </Text>{adresspostcode}</text>
                        <text style={styles.a} className="" >{adresscountry}</text>
                    </View>  
                    <View style={styles.rowcontainer}>
                        <text style={styles.a} className="" ><Text>Name: </Text>{adressname}</text>
                        <text style={styles.a} className="" ><Text>Surname: </Text>{adresslastname}</text>
                        <text style={styles.a} className="" >{adressphonenumber}</text>
                    </View>

                    <text className="" ><Text>Comments: </Text>{productcomment}</text>
            </View>
            <View style={styles.statuscontainer} >
                <text >Status: {status}</text>   
            </View>
            <View style={styles.statuscontainer} >
                <text >Actions</text>
                {!cancelshow && <Button buttonStyle= "btn--outline2" onClick={()=>{setcancelshow(true);setTimeout(() => {setcancelshow(false);}, 2000)}}>Cancel Order <i class="fa-solid fa-xmark"></i></Button>}
                {cancelshow && <Button buttonStyle="btn--outlinered" onClick={deleteorder}>CANCEL PERMANENTLY</Button>}
                {((issafemodemyframe && status === "waiting for payment")||!issafemodemyframe) && <Button buttonStyle= "btn--outline2" onClick={()=>window.location.href = "./edit-order-admin?uid="+JSON.stringify(ordernumber).replaceAll("\"","")+"&uid2="+JSON.stringify(useruid).replaceAll("\"","")}>Edit Order <i class="fa-solid fa-pen"></i>  </Button>}  
                {((issafemodemyframe && status === "waiting for payment")||!issafemodemyframe) && <Button buttonStyle= "btn--outline2" onClick={() => approvepayment()} >Aprove payment <i class="fa-solid fa-hand-holding-dollar"></i></Button>}
                {((issafemodemyframe && status === "waiting for supply")||!issafemodemyframe) && <Button buttonStyle= "btn--outline2" onClick={() => markshipped()}>Mark Shipped <i class="fa-solid fa-truck"></i></Button>}
                {((issafemodemyframe && status === "shipped")||!issafemodemyframe) && <Button buttonStyle= "btn--outline2" onClick={() => markdelivered()}>Mark Delivered <i class="fa-solid fa-bag-shopping"></i></Button>}                    
            </View>
            </View>

        </div>

    );
}
const styles = {
  container: {
    marginTop: 5,
    flexDirection: "row",
    marginBottom: 5,
    flex: 1
  },
  infobarcontainer: {
    textAlign: 'left',
    marginBottom: 5,
    flex:5,
  },
  statuscontainer: {
    textAlign: 'center',
    marginBottom: 0,
    marginLeft: 5,
    flex:1.2,
  },
  actionscontainer: {
    textAlign: 'center',
    marginBottom: 5,
    marginLeft: 5,
    flex:1.2,
    justifyContent: 'space-between',
  },
  rowcontainer: {width: "95%",
    textAlign: 'left',
    flexDirection: "row",
    marginBottom: 5,
    justifyContent: 'space-between',
    flex:1.2,
  },  
  a:{
    width: "33%",
   }
}
export default ListItemAdmin;