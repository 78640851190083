import React from "react"
import {Link} from "react-router-dom"
import CardItem from "./CardItem";
import "./Cards.css";
import '../languages/i18n'; 
import {useTranslation} from 'react-i18next';
import { Text} from "react-native";

function Cards() {
const {t, i18n} = useTranslation();
  return (
    <>
        <div className="cards">
            <h1>{t("brandtitle")}</h1>
            <view className="cards__container"><text className="form__text5">{t("visitbrandsweb")}</text></view>
            <div className="cards__container">
                <div className="cards__wrapper">
                    <ul className="cards__items">
                        <CardItem 
                        src="https://logowik.com/content/uploads/images/trendyolcom2977.jpg"
                        text="trendyol.com" 
                        label="All Products"
                        path="https://www.trendyol.com" />

                        <CardItem 
                        src="https://www.mavicompany.com/i/assets/images/site/footer-logo.png"
                        text="mavi.com" 
                        label="Textile Products"
                        path="https://www.mavi.com/jeanguide" />
                                                <CardItem 
                        src="https://static.tildacdn.com/tild3763-6135-4366-a530-396234383739/190602_175.jpg"
                        text="massimodutti.com/tr" 
                        label="Textile Products"
                        path="https://www.massimodutti.com/tr" />
                                                <CardItem 
                        src="https://cdn.webrazzi.com/uploads/2019/12/n11-937_hd_hd.jpeg"
                        text="n11.com" 
                        label="Textile Products"
                        path="https://www.n11.com/" />
                                                <CardItem 
                        src="https://upload.wikimedia.org/wikipedia/commons/3/32/Derimod_logo.png"
                        text="derimod.com.tr" 
                        label="All Products"
                        path="https://www.derimod.com.tr/" />
                    </ul>
                    <ul className="cards__items">
                        <CardItem 
                        src="https://st.mngbcn.com/images/headerNew/logos/mango.svg"
                        text="shop.mango.com/tr" 
                        label="All Products"
                        path="https://shop.mango.com/tr" />

                        <CardItem 
                        src="https://upload.wikimedia.org/wikipedia/commons/7/72/Pull%26Bear_logo.svg"
                        text="pullandbear.com/tr" 
                        label="Textile Products"
                        path="https://www.pullandbear.com/tr/en/" />
                                                <CardItem 
                        src="https://bershkafranchise.com/wp-content/uploads/2021/01/a0b60abc6cc2ae6cfc124d3a1278ec72.jpg"
                        text="bershka.com/tr" 
                        label="Textile Products"
                        path="https://www.bershka.com/tr/" />
                                                <CardItem 
                        src="https://www.terracity.com.tr/fileadmin/user_upload/GLOBAL/brand_stores/logos/stradivarius.png"
                        text="stradivarius.com/tr" 
                        label="Textile Products"
                        path="https://www.stradivarius.com/tr/" />
                                                <CardItem 
                        src="https://st-vans.mncdn.com/Content/img/vanslogo.svg"
                        text="vans.com.tr" 
                        label="All Products"
                        path="https://www.vans.com.tr/" />
                    </ul>
                    <ul className="cards__items">
                        <CardItem 
                        src="https://st-levis.mncdn.com/Content/img/levis-logo-2-t.png"
                        text="levis.com.tr" 
                        label="All Products"
                        path="https://www.levis.com.tr/" />

                        <CardItem 
                        src="https://cdn-gap.akinon.net/static_omnishop/gap396/assets/img/gap-logo.svg"
                        text="gap.com.tr" 
                        label="Textile Products"
                        path="https://gap.com.tr/" />
                                                <CardItem 
                        src="https://logos-download.com/wp-content/uploads/2016/06/Missha_logo-700x230.png"
                        text="missha.com.tr" 
                        label="Textile Products"
                        path="https://missha.com.tr/" />
                                                <CardItem 
                        src="https://www.terracity.com.tr/fileadmin/user_upload/GLOBAL/brand_stores/logos/adl.jpg"
                        text="adl.com.tr" 
                        label="Textile Products"
                        path="https://www.adl.com.tr/" />
                                                <CardItem 
                        src="https://www.aker.com.tr/Data/EditorFiles/aker/logo.svg"
                        text="aker.com.tr" 
                        label="All Products"
                        path="https://www.aker.com.tr/" />
                    </ul>
                    <ul className="cards__items">
                    <CardItem 
                        src="https://vorwerk-eshop-prod-media.s3-eu-west-1.amazonaws.com/sys-master/images/hd0/hbc/8797201235998/logo-vorwerk.png"
                        text="thermomix.vorwerk.com.tr" 
                        label="All Products"
                        path="https://thermomix.vorwerk.com.tr/" />

                        <CardItem 
                        src="https://www.halklailiskiler.com/resim/800/600/logo4_1492497388.jpg"
                        text="vakko.com/en" 
                        label="Textile Products"
                        path="https://www.vakko.com/en/" />
                                                <CardItem 
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fd/Zara_Logo.svg/1024px-Zara_Logo.svg.png"
                        text="zara.com/tr" 
                        label="All Products"
                        path="https://www.zara.com/tr/" />

                        <CardItem 
                        src="https://img2-ipekyol.mncdn.com/ipekyol/frontend/images/new-site-logo.svg"
                        text="ipekyol.com.tr" 
                        label="Textile Products"
                        path="https://www.ipekyol.com.tr/" />
                                                <CardItem 
                        src="https://www.avva.com.tr/Uploads/images/logo.png"
                        text="avva.com.tr" 
                        label="All Products"
                        path="https://www.avva.com.tr/" />

                    </ul>
                    <ul className="cards__items">
                        
                    <CardItem 
                        src="https://seeklogo.com/images/D/ds-damat-logo-17BB701A46-seeklogo.com.png"
                        text="dsdamat.com" 
                        label="All Products"
                        path="https://www.dsdamat.com/" />

                        <CardItem 
                        src="https://kurumsal.boyner.com.tr/images/contentimages/images/boynernewlogo2015.jpg"
                        text="boyner.com.tr" 
                        label="Textile Products"
                        path="https://www.boyner.com.tr/" />
                        <CardItem 
                        src="https://cdn.beymen.com/assets/desktop/img/beymen-logo.svg"
                        text="beymen.com" 
                        label="All Products"
                        path="https://www.beymen.com/" />

                        <CardItem 
                        src="https://dfcdn.defacto.com.tr/AssetsV2/dist/img/de-facto-logo-light-v2.svg"
                        text="defacto.com.tr" 
                        label="Textile Products"
                        path="https://defacto.com.tr" />
                                                <CardItem 
                        src="https://logowik.com/content/uploads/images/349_lcwaikiki1.jpg"
                        text="lcwaikiki.com" 
                        label="Textile Products"
                        path="https://www.lcwaikiki.com/tr-TR/TR" />
                                                <CardItem 
                        src="https://upload.wikimedia.org/wikipedia/commons/c/c2/Ki%C4%9F%C4%B1l%C4%B1_logo.png"
                        text="kigili.com" 
                        label="All Products"
                        path="https://www.kigili.com/" />


                    </ul>
                    <ul className="cards__items">
                        
                        <CardItem 
                            src="https://yt3.ggpht.com/ytc/AKedOLRmYB0I2LRWlS8HTG3DXwUBTi2vUAQWougX5yUFFA=s900-c-k-c0x00ffffff-no-rj"
                            text="madamecoco.com" 
                            label="All Products"
                            path="https://www.madamecoco.com/" />
    
                            <CardItem 
                            src="https://www.penti.com/medias/logo.svg?context=bWFzdGVyfGltYWdlc3wzODk5fGltYWdlL3N2Zyt4bWx8aDNhL2g5OC84Nzk2MTYzNTA2MjA2L2xvZ28uc3ZnfGEzY2Y2NGMzMTBmMTM3MDg4ODc2MDA2MmM1NTNjZDViOTE4NjUyZTFkOWNkMTkyYmFkODU4ZmVlMjEyZTQ3NTI"
                            text="penti.com/tr" 
                            label="Textile Products"
                            path="https://www.penti.com/tr/" />
                            <CardItem 
                            src="https://cdn-tac-new.mncdn.com/Themes/Tac/Content//images/logo.png"
                            text="tac.com.tr" 
                            label="All Products"
                            path="https://www.tac.com.tr/" />
    
                            <CardItem 
                            src="https://upload.wikimedia.org/wikipedia/tr/d/de/Collezione_logo.jpg"
                            text="collezione.com" 
                            label="Textile Products"
                            path="https://www.collezione.com/" />
                                                    <CardItem 
                            src="https://img-kotonw.mncdn.com/static/images/11131503771678/kotonlogo-2.png"
                            text="koton.com/en" 
                            label="Textile Products"
                            path="https://www.koton.com/en/" />
                                                    <CardItem 
                            src="https://www.flo.com.tr/pub/assets/flo-v2/images/flo-logo.svg"
                            text="flo.com.tr" 
                            label="All Products"
                            path="https://www.flo.com.tr/" />
    
    
                        </ul>
                        <ul className="cards__items">
                        
                        <CardItem 
                            src="https://cdn-ayakkabidunyasi.mncdn.com/Themes/AyakkabiDunyasi/Content/_images/logo.svg"
                            text="ayakkabidunyasi.com.tr" 
                            label="All Products"
                            path="https://www.ayakkabidunyasi.com.tr/" />
    
                            <CardItem 
                            src="https://rppetrivka.com/upload/iblock/cec/Colins_logo_fb.jpg"
                            text="colins.com.tr" 
                            label="Textile Products"
                            path="https://www.colins.com.tr/" />
                            <CardItem 
                            src="https://marka-logo.com/wp-content/uploads/2020/12/Columbia-Logo-2011-simdi.jpg"
                            text="columbia.com.tr" 
                            label="All Products"
                            path="https://www.columbia.com.tr/" />
                            <CardItem 
                            src="https://logowik.com/content/uploads/images/631_dyson_logo.jpg"
                            text="dyson.com.tr" 
                            label="Textile Products"
                            path="https://www.dyson.com.tr/" />
                            <CardItem 
                            src="https://www.krc.com.tr/catalog/view/assets/images/logos/karaca.svg"
                            text="krc.com.tr" 
                            label="All Products"
                            path="https://www.krc.com.tr/" />
    
                        </ul>
                        <ul className="cards__items">
                        
                        <CardItem 
                            src="https://logos-world.net/wp-content/uploads/2022/01/Crocs-Logo-700x394.png"
                            text="crocs.com.tr" 
                            label="All Products"
                            path="https://www.crocs.com.tr/ " />
                            <CardItem 
                            src="https://play-lh.googleusercontent.com/WBqAj8hFBWpamITe8saODnIIDqd1YF6QDJnd-kSOXKgz5AQs2G_V8mgJGNlhX_JKFg=s180-rw"
                            text="addax.com.tr" 
                            label="Textile Products"
                            path="https://www.addax.com.tr/" />
                            <CardItem 
                            src="https://www.arkady-pankrac.cz/fileadmin/_processed_/d/5/csm_Logo_PierreCardin_9ab65b45fb.png"
                            text="pierrecardin.com.tr" 
                            label="All Products"
                            path="https://www.pierrecardin.com.tr/" />
                            <CardItem 
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Adidas_Logo.svg/800px-Adidas_Logo.svg.png"
                            text="adidas.com.tr" 
                            label="Textile Products"
                            path="https://www.adidas.com.tr/tr " />
                            <CardItem 
                            src="https://www.forummagnesia.com/media/image/2985M9DWEB1SBU.png"
                            text="englishhome.com" 
                            label="All Products"
                            path="https://www.englishhome.com/ " />
                        </ul>
                        <ul className="cards__items">
                        <CardItem 
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a6/Logo_NIKE.svg/800px-Logo_NIKE.svg.png"
                            text="nike.com/tr" 
                            label="All Products"
                            path="https://www.nike.com/tr/ " />
                            <CardItem 
                            src="https://aydinli-polo.b-cdn.net/static_omnishop/polo625/assets/images/branding/USPA-LOGO-NEW.svg"
                            text="tr.uspoloassn.com" 
                            label="Textile Products"
                            path="https://tr.uspoloassn.com" />
                            <CardItem 
                            src="https://seeklogo.com/images/P/puma-logo-B8E03BAABF-seeklogo.com.png"
                            text="tr.puma.com" 
                            label="All Products"
                            path="https://tr.puma.com/ " />
                            <CardItem 
                            src="https://upload.wikimedia.org/wikipedia/commons/b/b2/Logo_oysho.svg"
                            text="oysho.com/tr" 
                            label="Textile Products"
                            path="https://www.oysho.com/tr/ " />
                            <CardItem 
                            src="https://i.pinimg.com/474x/0c/49/e6/0c49e6d99ba66a0b07afbc33ac64eab2.jpg"
                            text="dilvin.com.tr" 
                            label="All Products"
                            path="https://www.dilvin.com.tr/" />
                        </ul>
                        <ul className="cards__items">
                        <CardItem 
                            src="https://yt3.ggpht.com/81mzCU0sa_NpfzPFkuNjtJzRPig6ajijP6h7ulBxGRxE-88aQBYMK1vQCwdGC3Uy5reGA9PzjA=s900-c-k-c0x00ffffff-no-rj"
                            text="marjin.com.tr" 
                            label="All Products"
                            path="https://www.marjin.com.tr/" />
                            <CardItem 
                            src="https://play-lh.googleusercontent.com/fN7Y0BTDsz_-vNcIS6JqVzUoz_YDO4gRGcr-ZQ9sCZ4sohqgR8wanmjZWnS0DRA8fw"
                            text="deripabuc.com" 
                            label="Textile Products"
                            path="https://www.deripabuc.com/" />
                            <CardItem 
                            src="https://www.armonika.com.tr/Uploads/EditorUploads/armonika-logo.png"
                            text="armonika.com.tr" 
                            label="All Products"
                            path="https://www.armonika.com.tr/" />
                            <CardItem 
                            src="https://files.sikayetvar.com/lg/cmp/14/144738.png?1614787182"
                            text="alacatistili.com" 
                            label="Textile Products"
                            path="https://www.alacatistili.com/" />
                            <CardItem 
                            src="https://files.sikayetvar.com/lg/cmp/15/156600.png?1615212344"
                            text="haliforum.com" 
                            label="All Products"
                            path="https://www.haliforum.com/" />
                        </ul>
                        <ul className="cards__items">
                        <CardItem 
                            src="https://files.sikayetvar.com/lg/cmp/19/196820.png?1639486738"
                            text="karnavalhome.com"
                            label="All Products"
                            path="https://www.karnavalhome.com/" />
                            <CardItem 
                            src="https://files.sikayetvar.com/lg/cmp/21/215042.png?1638445623"
                            text="homefesto.com" 
                            label="Textile Products"
                            path="https://www.homefesto.com/" />
                            <CardItem 
                            src="https://www.konforhali.com/Data/EditorFiles/tasarim/logo.svg"
                            text="konforhali.com" 
                            label="All Products"
                            path="https://www.konforhali.com/" />
                            <CardItem 
                            src="https://www.quzu.com.tr/CustomCss/ticimax/images/Logo.png"
                            text="quzu.com.tr" 
                            label="Textile Products"
                            path="https://www.quzu.com.tr/" />
                            <CardItem 
                            src="https://i.pinimg.com/originals/dc/2a/eb/dc2aeb310cf18006c200f78a6ed467a8.jpg"
                            text="skechers.com.tr" 
                            label="All Products"
                            path="https://www.skechers.com.tr/" />
                        </ul>
                        <ul className="cards__items">
                        <CardItem 
                            src="https://seeklogo.com/images/B/brillant-logo-8E4C402D5C-seeklogo.com.png"
                            text="shop.brillant.com"
                            label="All Products"
                            path="https://shop.brillant.com/" />
                            <CardItem 
                            src="https://www.volper.com.tr/Images/logo.png"
                            text="volper.com.tr" 
                            label="All Products"
                            path="https://www.volper.com.tr/" />
                            <CardItem 
                            src="https://www.atolyeperde.com/uploads/logo/logo_5f44ebbb51a29.png"
                            text="atolyeperde.com" 
                            label="Textile Products"
                            path="https://www.atolyeperde.com/" />
                            <CardItem 
                            src="https://www.kcgruptekstil.com/custom/header_left_1_0.jpg?rn1652965199"
                            text="kcgruptekstil.com"
                            label="All Products"
                            path="https://www.kcgruptekstil.com/" />
                        </ul>
                        <ul className="cards__items">
                        <CardItem 
                        src="https://www.altinbas.com/images/logo.png"
                        text="altinbas.com" 
                        label="All Products"
                        path="https://www.altinbas.com/" />

                        <CardItem 
                        src="https://st.myideasoft.com/idea/ih/27/themes/selftpl_60a37ebe015b3/assets/uploads/logo.png?revision=1652962754"
                        text="altincizgi.com" 
                        label="Textile Products"
                        path="https://www.altincizgi.com/" />
                                                <CardItem 
                        src="https://www.armine.com/CustomCss/images/logo.svg"
                        text="armine.com" 
                        label="Textile Products"
                        path="https://www.armine.com/" />
                                                <CardItem 
                        src="https://seeklogo.com/images/A/atasay-logo-5FD3554C3B-seeklogo.com.png"
                        text="atasay.com" 
                        label="Textile Products"
                        path="https://www.atasay.com/" />
                                                <CardItem 
                        src="https://cdn.worldvectorlogo.com/logos/samsonite.svg"
                        text="samsonite.com.tr" 
                        label="All Products"
                        path="https://www.samsonite.com.tr/" />
                                                                        <CardItem 
                        src="https://www.deriden.com.tr/CustomCss/images/logo.png"
                        text="deriden.com.tr" 
                        label="All Products"
                        path="https://www.deriden.com.tr/" />
                                                                                                <CardItem 
                        src="https://www.ninewest.com.tr/pub/assets/ninewest/img/logo.jpg"
                        text="ninewest.com.tr" 
                        label="All Products"
                        path="https://www.ninewest.com.tr/" />
                    </ul>
                </div>
            </div>
        </div>
        <div style={{padding:"50px",textAlign:"center"}}>
         <text className="form__logotext" to="/" >ERVESTA</text><br/>
        <text className="form__logotextslogan" translate="no" to="/"  >shop smart</text>
       </div>
    </>
  );
}

export default Cards;