//import '../../App.css';
import { db, auth, user } from '../../firebase';
import './pagescss/signup.css';
import { Button } from "../Button";
import React,{useState,useRef,useEffect} from 'react';
import {StyleSheet,CheckBox, TextInput ,Text, Image} from "react-native";
import { Link } from 'react-router-dom';
import Footer from "../Footer";
//import MaskInput from 'react-native-mask-input';
import MaskInput from 'react-maskinput';
import '../../languages/i18n'; 
import {useTranslation} from 'react-i18next';
import BrandsTicker from "../BrandsTicker";

let isfirsttime =  1;

function Services() {

  
  var myRef = useRef();
  function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   return result;
}
function getDate(){
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;
    return today;
}

  function executeScroll () {
    setTimeout(() => {
        //document.getElementById("emaillogin").scroll(0,0)
        window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop-100 })
      },500);
  }
        

  const [uid, setuid] = useState("nouid");
  const changeuid = (text) => setuid(text);

  const [signedin, setSignedIn] = useState(false);
  const changesignstatus = (mybool) => setSignedIn(mybool);

  const [trykztrate, settrykztrate] = useState(0);
  
  
  const [showlogin, setshowlogin] = useState(false);
  const changeshowlogin = (mybool2) => {setshowlogin(mybool2)};

  const [mailtext, setmailtext] = useState("mail");

  const [isdisabled, setDisable] = useState(false);

  const [isSelected, setSelection] = useState(true);

  auth.onAuthStateChanged(function(user) {
    if (user) {

      changesignstatus(true);
      //changeshowlogin(false);
      setmailtext(user.email);
      changeuid(user.uid+"")
      
    }else {
        changesignstatus(false);
        changeuid("nouid");
    }
  });
  
  const {t, i18n} = useTranslation();

  function getTRYKZT(){

    db.ref("/trykzt")
    .once("value")
    .then(snapshot => {
      const d = new Date();
      var day = d.getDay();
      if(parseInt(day) - snapshot.child("lastday").val()>1||parseInt(day) - snapshot.child("lastday").val()<-1){
          
        fetch('https://v6.exchangerate-api.com/v6/5ad59fb5257e4df5b9ceefbb/pair/TRY/KZT').then((response) => response.json()).then((json) => {
            settrykztrate(parseFloat(json.conversion_rate).toFixed(2));
            db.ref("/trykzt/").set({
              ratio: parseFloat(json.conversion_rate).toFixed(2),
              lastday: day,
                })
                .then(() => {
                    return parseFloat(json.conversion_rate).toFixed(2);
                  })
            }).catch((error) => {
                console.error(error);
            });
          }
      settrykztrate(parseFloat(snapshot.child("ratio").val()).toFixed(2));
      });
  }
  
  getTRYKZT();

      const registeruser = () =>{
        
        
        if(validatesignupinputs())
        {placeorder("register");

            auth.createUserWithEmailAndPassword(document.getElementById("emailRegister").value+"", document.getElementById("passwordRegister").value+"")
                      .then((res) =>{
                        db.ref("/users/"+res.user.uid).set({
                          name: ""+document.getElementById("firstNameRegister").value,
                          surname: document.getElementById("lastNameRegister").value+"",
                          email: document.getElementById("emailRegister").value,
                          registerdate: getDate(),
                            })
                            .then(() => {
                              placeorder();
                            })
                      })
                      .catch(error => { alert(error.message) })    
        }
        };
    const signinuser = () =>{
      if(validatesignininputs()){
            auth.signInWithEmailAndPassword(document.getElementById("emaillogin").value+"", document.getElementById("passwordlogin").value+"")
          .then(() => {
            placeorder();
          })
          .catch(error => {alert(error.message) })
      }
      
      };

    function validatesignupinputs() {
      var outputmsg = "";
      if(!validateemail(document.getElementById("emailRegister").value)){
        outputmsg = t("debug14")
        document.getElementById("emailRegister").style.border="2px solid red";
      }     
      if(document.getElementById("firstNameRegister").value.length < 2){
        outputmsg = outputmsg+t("debug16")
        document.getElementById("firstNameRegister").style.border="2px solid red";
      }    
      if(document.getElementById("lastNameRegister").value.length < 2){
        outputmsg = outputmsg+t("debug17")
        document.getElementById("lastNameRegister").style.border="2px solid red";
      }    
      if(document.getElementById("passwordRegister").value.length < 6){
        outputmsg = outputmsg+t("debug18")
        document.getElementById("passwordRegister").style.border="2px solid red";
      } 
      if(document.getElementById("passwordRegister").value+"" !== document.getElementById("confirmPassword").value+""){
        outputmsg = outputmsg+t("debug19")
        document.getElementById("confirmPassword").style.border="2px solid red"; 
      }
      if(outputmsg === "")
      return true;
      else {
        alert(outputmsg);
        setDisable(false);
        return false;}
    }
      function validatesignininputs() {
        var outputmsg = "";
        if(!validateemail(document.getElementById("emaillogin").value)){
          outputmsg = t("debug14")
          document.getElementById("emaillogin").style.border="2px solid red";
        }    
        if(document.getElementById("passwordlogin").value.length < 6){
          outputmsg = outputmsg+t("debug15")
          document.getElementById("passwordlogin").style.border="2px solid red";
        } 
        if(outputmsg === "")
        return true;
        else {
          alert(outputmsg);
          setDisable(false);
          return false;}
      }
  function validateproductinputs() {
    var outputmsg = "";
    if(document.getElementById("productlink").value.length<5){
      outputmsg = t("debug1")
      document.getElementById("productlink").style.border="2px solid red";
    }    
    if(!document.getElementById("productlink").value.includes(".")){
      outputmsg = outputmsg+t("debug2")
      document.getElementById("productlink").style.border="2px solid red";
    } 
    if(document.getElementById("productname").value.length<2){
      outputmsg = outputmsg+t("debug3")
      document.getElementById("productname").style.border="2px solid red";
    }
    if(document.getElementById("productsize").value.length<1){
      outputmsg = outputmsg+t("debug4")
      document.getElementById("productsize").style.border="2px solid red";
    }
    if(document.getElementById("productcolor").value.length<1){
      outputmsg = outputmsg+t("debug5")
      document.getElementById("productcolor").style.border="2px solid red";
    }
    if(document.getElementById("productpriceintl").value.length<1){
      outputmsg = outputmsg+t("debug6")
      document.getElementById("productpriceintl").style.border="2px solid red";
    }
    if(document.getElementById("adressline1").value.length<8){
      outputmsg = outputmsg+t("debug7")
      document.getElementById("adressline1").style.border="2px solid red";
    }
    if(document.getElementById("adresscity").value.length<2){
      outputmsg = outputmsg+t("debug8")
      document.getElementById("adresscity").style.border="2px solid red";
    }
    if(document.getElementById("adresspostcode").value.length<2){
      outputmsg = outputmsg+""+t("debug9")
      document.getElementById("adresspostcode").style.border="2px solid red";
    }
    if(document.getElementById("adressname").value.length<2){
      outputmsg = outputmsg+""+t("debug10")
      document.getElementById("adressname").style.border="2px solid red";
    }
    if(document.getElementById("adresslastname").value.length<2){
      outputmsg = outputmsg+t("debug11")
      document.getElementById("adresslastname").style.border="2px solid red";
    }
    //alert("phn: "+parseInt(document.getElementById("adressphonenumber").replaceAll("(","").replaceAll(")","").replaceAll("+","").replaceAll("-","").replaceAll(" ", "")));
    if(parseInt(document.getElementById("adressphonenumber").value.replaceAll("(","").replaceAll(")","").replaceAll("+","").replaceAll("-","").replaceAll(" ", ""))<70000000001 || document.getElementById("adressphonenumber").value.length<10){
      outputmsg = outputmsg+t("debug12")
      document.getElementById("adressphonenumber").style.border="2px solid red";
    }
    if(document.getElementById("selecter").value === "chooseacategory"){
      outputmsg = outputmsg+t("debug13")
      document.getElementById("selecter").style.border="2px solid red";
    }
    if(outputmsg === "")
    return true;
    else {
      alert(outputmsg);
      setDisable(false);
      return false;}
  }
  function placeorder() {
      
      setDisable(true);
      if(validateproductinputs()){

          if(auth.currentUser != null ){//signedin
          var orderid = makeid(10);
        
          console.log("Data set1.")
          db.ref("/orders/"+orderid).set({
              productlink: document.getElementById("productlink").value,
              productname: document.getElementById("productname").value,
              productcode: document.getElementById("productcode").value,
              productsize: document.getElementById("productsize").value,
              productcolor: document.getElementById("productcolor").value,
              productquantity: document.getElementById("productquantity").value,
              productpriceintl: document.getElementById("productpriceintl").value,
              productervestafee: document.getElementById("productervestafee").placeholder,
              producttotalcost: document.getElementById("producttotalcost").placeholder,
              productcomment: document.getElementById("productcomment").value,
              useruid: auth.currentUser.uid,
              usermail: mailtext,
              status: "waiting for payment",
              date: getDate(),
              adressline1: document.getElementById("adressline1").value,
              adresscity: document.getElementById("adresscity").value,
              adresspostcode: document.getElementById("adresspostcode").value,
              adresscountry: document.getElementById("adresscountry").value,
              adressphonenumber: document.getElementById("adressphonenumber").value,
              adressname: document.getElementById("adressname").value,
              adresslastname: document.getElementById("adresslastname").value,
              material: document.getElementById("selecter").value,
            })
            .then(() => {
                    console.log("Data set2.")
                    db.ref("/users/"+auth.currentUser.uid+"/orders/"+orderid).set({
                      productlink: document.getElementById("productlink").value,
                      productname: document.getElementById("productname").value,
                      productcode: document.getElementById("productcode").value,
                      productsize: document.getElementById("productsize").value,
                      productcolor: document.getElementById("productcolor").value,
                      productquantity: document.getElementById("productquantity").value,
                      productpriceintl: document.getElementById("productpriceintl").value,
                      productervestafee: document.getElementById("productervestafee").placeholder,
                      producttotalcost: document.getElementById("producttotalcost").placeholder,
                      productcomment: document.getElementById("productcomment").value,
                      useruid: auth.currentUser.uid,
                      usermail: mailtext,
                      status: "waiting for payment",
                      date: getDate(),
                      adressline1: document.getElementById("adressline1").value,
                      adresscity: document.getElementById("adresscity").value,
                      adresspostcode: document.getElementById("adresspostcode").value,
                      adresscountry: document.getElementById("adresscountry").placeholder,
                      adressphonenumber: document.getElementById("adressphonenumber").value,
                      adressname: document.getElementById("adressname").value,
                      adresslastname: document.getElementById("adresslastname").value,
                      material: document.getElementById("selecter").value,
                    })
                    .then(() => {
                      console.log("order saved on profile")
                      if(isSelected){
                          db.ref("/users/"+auth.currentUser.uid+"/adress").set({
                            adressline1: document.getElementById("adressline1").value,
                            adresscity: document.getElementById("adresscity").value,
                            adresspostcode: document.getElementById("adresspostcode").value,
                            adresscountry: document.getElementById("adresscountry").placeholder,
                            adressphonenumber: document.getElementById("adressphonenumber").value,
                            adressname: document.getElementById("adressname").value,
                            adresslastname: document.getElementById("adresslastname").value,
                          })
                          .then(() => {
                            const oid = orderid.toString();
                            window.location.href = "./post-order?uid="+JSON.stringify(oid).replaceAll("\"","");
                            console.log("adress saved on profile")
                            setDisable(false);
                          }).catch(error => { alert(error.message) })   
                      }else {
                        const oid = orderid.toString();
                            window.location.href = "./post-order?uid="+JSON.stringify(oid).replaceAll("\"","");
                            console.log("adress saved on profile")
                            setDisable(false);
                      }
                    }).catch(() => {setDisable(false);});
            }).catch(error => { alert(error.message +"uid: "+uid) })
                          
          
          }else{
              //if not signed-in
            changeshowlogin(true);
            executeScroll();setDisable(false);
  
            }
        }else{
          //if not validated
          setDisable(false);
        }
    
    };
    function validateemail(text) {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      if (reg.test(text) === false) {
        return false;
      }
      else {return true;
      }
    }
      const handleInputTextChange = () =>{

          document.getElementById("productpriceintl").value = document.getElementById("productpriceintl").value.replaceAll(",",".");
            
          if(document.getElementById("selecter").value === "metal"){
            document.getElementById("productervestafee").placeholder = (parseFloat(document.getElementById("productquantity").value)*parseFloat(document.getElementById("productpriceintl").value)*0.5*trykztrate).toFixed(0)+" KZT";
            if(parseFloat(document.getElementById("productervestafee").placeholder) < parseFloat(document.getElementById("productquantity").value*4500)){
              document.getElementById("productervestafee").placeholder = parseFloat(document.getElementById("productquantity").value*4500)+" KZT";
            }
            document.getElementById("producttotalcost").placeholder=(
            parseFloat(document.getElementById("productquantity").value)*parseFloat(document.getElementById("productpriceintl").value)*trykztrate
            +parseFloat(document.getElementById("productervestafee").placeholder)).toFixed(0)+" KZT";
          }else {
          document.getElementById("productervestafee").placeholder = (parseFloat(document.getElementById("productquantity").value)*parseFloat(document.getElementById("productpriceintl").value)*0.2*trykztrate).toFixed(0)+" KZT";
            if(parseFloat(document.getElementById("productervestafee").placeholder) < parseFloat(document.getElementById("productquantity").value*4500)){
              document.getElementById("productervestafee").placeholder = parseFloat(document.getElementById("productquantity").value*4500)+" KZT";
            }
            document.getElementById("producttotalcost").placeholder=(
            parseFloat(document.getElementById("productquantity").value)*parseFloat(document.getElementById("productpriceintl").value)*trykztrate
            +parseFloat(document.getElementById("productervestafee").placeholder)).toFixed(0)+" KZT";
          }

        };


    return(
      <div className="topbody">
        <text className="form__titellabel" for="firstName">{t("placeanordervideo")}</text><br />
        
        <iframe width="400" minWidth="350" height="400" src="https://www.youtube.com/embed/BXnujsRO-1g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <br/>
      <text className="form__titellabel" for="firstName">{t("placeanorder")}</text><br />
                           
     
      <div className="form">

        <text className="form__titellabel" for="firstName">{t("productdetails")}</text><br />
                  <Text className="" >{t("fillblanks")}</Text>
          <div className="form-body">
              <div className="username">
                  <Text id="pldisc" className="" >{t("productlink")}*</Text>
                  <input maxlength="10000" className="form__input" type="text" id="productlink" placeholder="https://www.dyson.com.tr/dyson-airwrap-styler-complete-long-iron-fuchsia"/>
                  <Text className="form__smalltext">{t("chooseproducts")}<Link className="form__label" to='/products'>brands</Link> {t("pagetolearn")}</Text><br/>
              </div>
              <div className="form-body2">
                <div>
                    <Text id="pndisc" className="" >{t("productname")}*</Text><br/>
                    <input maxlength="300" type="text" name="" id="productname"  className="form__halfinput" placeholder={t("forexampleshirt")}/>
                </div>
                <div>
                    <Text className="" >{t("productcode")}</Text><br/>
                    <input maxlength="100" type="text" name="" id="productcode"  className="form__halfinput" placeholder={t("forexample1234")}/>
                </div>
                <Text className="form__smalltext" ><br/>{t("nameoftheproductasin")}</Text><br/>
              </div>
              <div/>
              <div className="form-body2">
                  <div className="password">
                    <Text id="psdisc" className="" >{t("productsize")}*</Text><br/>
                    <input maxlength="10" type="text" id="productsize"  className="form__halfinput" placeholder={t("forexamplel")}/>
                  </div>
                  <div className="password">
                    <Text id="pcdisc" className="" >{t("productcolor")}*</Text><br/>
                    <input maxlength="30" className="form__halfinput" type="text"  id="productcolor" placeholder={t("forexamplegreen")}/>
                  </div>
                  <div className="confirm-password">
                    <Text  className="" >{t("productquantity")}*</Text><br/>
                    <input  className="form__halfinput" onChange={handleInputTextChange} min="1" max="20" type="number" id="productquantity" placeholder="1" defaultValue="1"/>
                  </div>
              </div>
              <Text className="" >{t("productcategory2")}*</Text>
              <select id="selecter" onChange={handleInputTextChange} className="form__halfinput" style={{marginLeft: 5, width:200,height:45}} name="cars">
                <option style={{color: "#444444"}} value="chooseacategory">{t("chooseacategory")}</option>
                <option value="nonmetal">{t("nonmetal")}</option>
                <option value="metal">{t("metal")}</option>
                <option value="preciousmetal">{t("preciousmetal")}</option>
              </select>
              <div  className="form-body2">
                  <div className="password">
                    <Text id="pitldisc" className="" >{t("productpriceintl")}*</Text><br/>
                    <input maxlength="20" type="text" id="productpriceintl" onChange={handleInputTextChange}  className="form__halfinput" placeholder={t("forexample99")}/>
                    <Text className="form__smalltext" ><br/>1 {t("turkishlira")} = {trykztrate} {t("kazakhtenge")}.</Text><br/>
                  </div>
                  <div className="password">
                    <Text className="" >{t("productervestafee")}</Text><br/>
                    <input  disabled className="form__halfinput" type="text"  id="productervestafee" placeholder="20% fee"/>
                  </div>
                  <div className="confirm-password">
                    <Text className="" >{t("producttotalcost")}</Text><br/>
                    <input  disabled className="form__halfinput" type="number" id="producttotalcost" placeholder="0 KZT" value="0 KZT"/>
                  </div>
              </div>
              <div  className="form-body">
                  <div className="password">
                    <Text  >Comments</Text><br/>
                    <input maxlength="2000" type="text" id="productcomment"  className="form__input" placeholder={t("tellusifyouneed")}/>
                  </div>
              </div>
              <br/>
              <text className="form__titellabel" for="firstName">{t("adressandrecieverinformation")}</text>
              <Text className="" >{t("makesureyouradressis")}</Text>
              
              <div  className="form-body">
                  <div className="password">
                    <Text id="al1disc" className="" >{t("adressline1")}*</Text><br/>
                    <input maxlength="2000" type="text" id="adressline1"  className="form__input" placeholder={t("youradressformal")}/>
                  </div>
              </div>
              <div  className="form-body2">
                  <div >
                    <Text id="acdisc" className="" >{t("adresscity")}*</Text><br/>
                    <input maxlength="50" type="text" id="adresscity"  className="form__halfinput" placeholder={t("almatynursultanetc")}/>
                  </div>
                  <div >
                    <Text id="apcdisc" className="" >{t("adresspostcode")}*</Text><br/>
                    <input maxlength="12" type="text" id="adresspostcode"  className="form__halfinput" placeholder={t("forexample05000")}/>
                  </div>
                  <div >
                    <Text className="" >{t("adresscountry")}*</Text><br/>
                    <input disabled type="text" id="adresscountry"  className="form__halfinput" placeholder="KAZAKHSTAN"/>
                  </div>
              </div>
              <div  className="form-body2">
                  <div >
                    <Text id="apndisc" className="" >{t("adressphonenumber")}*</Text><br/>
                    <MaskInput id="adressphonenumber" className="form__halfinput"  mask={'+7 (000) 000-00-00'}  maskChar="_" placeholder="+7 (000) 000-00-00"/>
                  </div>
                  <div >
                    <Text id="afndisc" className="" >{t("adressname")}*</Text><br/>
                    <input maxlength="50" type="text" id="adressname"  className="form__halfinput" placeholder={t("adressname")}/>
                  </div>
                  <div >
                    <Text id="alndisc" className="" >{t("adresslastname")}*</Text><br/>
                    <input maxlength="50" type="text" id="adresslastname"  className="form__halfinput" placeholder={t("adresslastname")}/>
                  </div>
              </div>
              <div  className="form-body2">
                <CheckBox style={{ width: 24, height: 24 }} value={isSelected} onValueChange={setSelection} ></CheckBox><Text className="" >{t("savemyadress")}</Text>
              </div>

              <br/>
              <text className="form__titellabel" for="firstName">{t("paymentmethod")}</text>
              <Text className="" >{t("choosepaymentmethod")}*</Text>
              
              <div  className="form-body2">
                <input checked="checked" style={{marginTop:"5px",marginRight:"5px"}} type="radio" id="html" name="fav_language" value="wiretransfer"/>
                <label for="html">{t("wiretransfer")}</label>
                <input style={{marginTop:"5px",marginRight:"5px",marginLeft:"15px"}}  disabled type="radio" id="css" name="fav_language" value="creditcard"/>
                <label style={{color:"#7E7E7E"}} for="css">{t("creditcard")} (not available yet)</label>
              </div>


          </div>
          <div class="footer2">
              <button id="placeorderbtn" disabled={isdisabled} className= "btn--disabled" onClick={placeorder}>{t("placemyorder")} <i class="fa-solid fa-arrow-right"></i></button>
          </div>
      </div>   
      
      {showlogin && <div ref={myRef} className="topbody">
      <text className="form__titellabel" for="firstName">{t("tocontinueorder")}</text><br />
      <div className="form">
        <text className="form__titellabel" for="firstName">{t("signin")}</text><br />
          <div className="form-body">
              <div className="email">
                  
                  <input  type="email" id="emaillogin" className="form__input" placeholder={t("email")}/>
              </div>
              <div className="password">
                <input className="form__input" type="password"  id="passwordlogin" placeholder={t('password')}/>
              </div>
          </div>
          <div class="footer">
              <Button buttonStyle= "btn--outline2" onClick={signinuser}>{t("signinandplaceorder")}{" >"}</Button>
          </div>
          <Link className="form__label" to='/home'>{t("forgotpass")}</Link>
      </div>    

      <text className="form__titellabel" for="firstName">{t("ifyoudontaccount")}</text><br />
      
      <div className="form">
        <text className="form__titellabel" for="firstName">{t("signup")}</text><br />
          <div className="form-body">
              <div className="username">
                  <input className="form__input" type="text" id="firstNameRegister" placeholder={t('firstname')}/>
              </div>
              <div className="lastname">
                  <input  type="text" name="" id="lastNameRegister"  className="form__input"placeholder={t('lastname')}/>
              </div>
              <div className="email">
                  <input  type="email" id="emailRegister" className="form__input" placeholder={t("email")}/>
              </div>
              <div className="password">
                  <input className="form__input" type="password"  id="passwordRegister" placeholder={t('password')}/>
              </div>
              <div className="confirm-password">
                  <input className="form__input" type="password" id="confirmPassword" placeholder={t('confirmpassword')}/>
              </div>
          </div>
          <div class="footer">
              <Button buttonStyle= "btn--outline2" onClick={registeruser} >{t("signupandplaceorder")}{" >"}</Button>
          </div>
      </div>   
      </div>  }

      <div style={{padding:"50px"}}>
         <text className="form__logotext" translate="no" to="/" >ERVESTA</text><br/>
        <text className="form__logotextslogan" translate="no" to="/"  >shop smart</text>
       </div>

      <Footer/>
      </div>  
    )       
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  checkboxContainer: {
    flexDirection: "row",
    marginBottom: 20,
  },
  checkbox: {
    alignSelf: "center",
  },
  label: {
    margin: 8,
  },
});


export default  Services;