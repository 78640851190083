import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import { Button } from "./Button";
import "./Navbar.css";
import { db } from '../firebase';
import { auth } from '../firebase';
import { user } from '../firebase';
import { Text,View,Image} from "react-native";
import '../languages/i18n'; 
import {useTranslation} from 'react-i18next';

function Navbar() {

    //****************************** */
      // Set an initializing state while Firebase connects

      
  //********************************************** */
    
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const [signedin, setSignedIn] = useState(false);
    const changesignstatus = (mybool) => setSignedIn(mybool);

    const [buttonText, setButtonText] = useState("Next");
    const changeText = (text) => setButtonText(text);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    
    function setlinksselected(value) {
        closeMobileMenu();
        
        document.getElementById("aboutuslink").className = "nav-links";
        document.getElementById("contactuslink").className = "nav-links";
        document.getElementById("howitworkslink").className = "nav-links";
        document.getElementById("placeanorderlink").className = "nav-links";
        document.getElementById("brandslink").className = "nav-links";

        if(!signedin)
        document.getElementById("signuplink").className = "nav-links";
        else
        document.getElementById("profilelink").className = "nav-links";

        if(value.length > 0)
        document.getElementById(value).className = "nav-linksactive";
      };

        const {t, i18n} = useTranslation();
  
        const [currentLanguage,setLanguage] =useState(localStorage.getItem("language"));
        
        function changeLanguage2(value) {
                setLanguage(value);
                i18n
                .changeLanguage(value)
                .then(() => {
                    localStorage.setItem("language", value);
                })
                .catch(err => console.log(err));
        };  
        
        //this is an non-professional way for solving a bug. if lang is set to russian,
        // it is not setting text in russian. only after refreshing page.

        if( currentLanguage == "en" && t('home') !== "Home") {
            changeLanguage2("en");
        }
        if( currentLanguage == "tr" && t('home') !== "Ana Sayfa") {
            changeLanguage2("tr");
        }
      const signoutfun = () =>{
        auth.signOut().then(function() {
          console.log('Signed Out');

        }, function(error) {
          console.error('Sign Out Error', error);
        });
      };
      
      auth.onAuthStateChanged(function(user) {
        if (user) {
          changesignstatus(true);
        
          changeText(user.email);
          
        }else {
            changesignstatus(false);
            changeText("asd")
        }
        
    });
    const showButton = () => {
        if(window.innerWidth <= 960){
            setButton(false);
        }else {
            setButton(true);
        }
    };

    useEffect(() => showButton(),[]);
    window.addEventListener("resize", showButton);

  return (
    <>
        <nav className="navbar">
            <div className="navbar-container">
                <Link to="/" className="navbar-logo" onClick={()=> setlinksselected("")}>
                    ERVESTA
                    
                </Link>
                <div className="menu-icon" onClick={handleClick}>
                    <i className={click ? "fas fa-times" : "fas fa-bars"}/>
                </div>
                <ul className = {click ? "nav-menu active" : "nav-menu"}>
                    <li className="nav-item">
                        <Link id="aboutuslink" to="/about-us" className="nav-links" onClick={()=> setlinksselected("aboutuslink")}>
                        <i class="fa-solid fa-circle-info"></i>&nbsp; {t('aboutus')}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link id="contactuslink" to="/contact-us" className="nav-links" onClick={()=> setlinksselected("contactuslink")}>
                        <i class="fa-solid fa-phone-flip"></i>&nbsp; {t('contactus')}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link id="howitworkslink" to="/how-it-works" className="nav-links" onClick={()=> setlinksselected("howitworkslink")}>
                        <i class="fa-solid fa-circle-question"></i>&nbsp; {t('howitworks')}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link id="placeanorderlink" to="/services" className="nav-links" onClick={()=> setlinksselected("placeanorderlink")}>
                            <i class="fa-solid fa-cart-shopping"></i>&nbsp; {t('placeanorder')}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link id="brandslink" to="/products" className="nav-links" onClick={()=> setlinksselected("brandslink")}>
                            <i class="fa-solid fa-star"></i>&nbsp; {t('brands')}
                        </Link>
                    </li>
                    <li className="nav-item">
                        {!signedin && <Link id="signuplink" to="/sign-up" className="nav-links" onClick={()=> setlinksselected("signuplink")}>
                            <Button buttonStyle= "btn--outline2">{t('signin')}</Button>
                            </Link>}
                        {signedin && 
                            <Link id="profilelink" to="/profile" className="nav-links" onClick={()=> setlinksselected("profilelink")}>
                                <i class="fa-solid fa-user"></i>&nbsp;
                                {buttonText}
                            </Link> }
                    </li>
                    
                <View style={{alignItems: 'center',textAlign: 'center', width:"30px",marginTop: "10px"}}>
                        <Button id="langbtnru" onClick={()=>{changeLanguage2("ru");}} buttonStyle= {currentLanguage ===   "ru" ?  "btn--languageactive" : "btn--language"}  style={{backgroundColor:"transparent",border: "0px solid transparent", justifyContent: 'space-between', flexDirection: "column"}}>
                            <Image source="/images/russia.png" style={{left:1, width: 20, height: 10}} />
                        </Button>
                        <Button id="langbtnen" onClick={()=>{changeLanguage2("en");}} buttonStyle= {currentLanguage !==   "en" ?  "btn--language" : "btn--languageactive"} style={{backgroundColor:"transparent",border: "0px solid transparent", justifyContent: 'space-between', flexDirection: "column"}}>
                            <Image source="/images/united-kingdom.png" style={{left:1, width: 20, height: 10}} />
                        </Button>
                        <Button id="langbtnen" onClick={()=>{changeLanguage2("tr");}} buttonStyle= {currentLanguage !==   "tr" ?  "btn--language" : "btn--languageactive"} style={{backgroundColor:"transparent",border: "0px solid transparent", justifyContent: 'space-between', flexDirection: "column"}}>
                            <Image source="/images/turkey.png" style={{left:1, width: 20, height: 10}} />
                        </Button>
                </View>
                </ul>
            </div>
        </nav>
    </>
  )
}

export default Navbar;