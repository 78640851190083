

import {Text,Image,View,TouchableOpacity,SafeAreaView} from "react-native";
import { db,auth } from '../firebase';
import React, { useState } from 'react';
import './pages/pagescss/signup.css';
import '../languages/i18n'; 
import {useTranslation} from 'react-i18next';

function ForCostumer() {
 
    const search = window.location.search; // returns the URL query String
    const params = new URLSearchParams(search); 
    const IdFromURL = params.get('uid'); 
    const {t, i18n} = useTranslation();

  return (
      <View style={{backgroundColor: 'white' , paddingBottom:"60px", paddingTop:"60px", textAlign:"center",flexDirection:"column",flexWrap:"wrap", flex:1,display:"flex",justifyContent:"center"}}>
      
      <View style={{marginLeft: "15%",marginRight:"15%",paddingBottom:"60px", paddingTop:"60px", textAlign:"center",flexDirection:"column",flexWrap:"wrap", flex:1,display:"flex",justifyContent:"center"}}>
      <h1>{t("costumerreviews")}</h1>
      <view className="cards__container"><text className="form__text5"></text></view>
      </View>
    <View style={styles.container1}>
        
        <View style={styles.container2}>
            <text className="form__text4">Кристина</text>
            <text className="form__text6">Получила своё платье от бренда Massimo Dutti! Заказ шёл до Казахстана 11-дней. На 14 день получила долгожданное платье по супер цене. Самое что удивило по прибытию ничего доплачивать не нужно было! А упаковка была просто идеальной! Спасибо Ервеста, вы классные! Скоро еще закажу</text>
            <View style={styles.container11}>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
            </View>
        </View>
        <View style={styles.container2}>
            <text className="form__text4">Анелья</text>
            <text className="form__text6">Это был мой первый заказ очень переживала касательно доставки. Получила свою сумку (Derimod) целости сохранности. Все прошло просто идеально: выкуп, сроки доставки, упаковка, товар!!! Спасибо вам за вашу работу! Всем советую этот сайт.</text>
            <View style={styles.container11}>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
            </View>
        </View>
        <View style={styles.container2}>
            <text className="form__text4">Бахтияр</text>
            <text className="form__text6">Уважаемая Ervesta! Спешу написать отзыв , так как получила удовольствие от Вашей работы, все очень четко , быстро на всех этапах заказа! Браво! С удовольствием буду заказывать вновь! Спасибо за Ваш труд и грамотную организацию всего процесса.</text>
            <View style={styles.container11}>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
            </View>
        </View>
        <View style={styles.container2}>
            <text className="form__text4">Азамат</text>
            <text className="form__text6">Авва брендінен заказ берген киімдерім келді. Сапасы өте жақсы. Осы бренд біздің елде қашан ашылады екен деп күтіп жүр едім. Өте қуаныштымын. Енді әрқашан сіздер арқылы Турциядан алып отыратын боламын.</text>
            <View style={styles.container11}>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/stargray.png" style={styles.imagecontainer2}/>
            </View>
        </View>
    </View>
      </View>
  );
}

const styles = {
    container1: {
      marginTop: 5,
      flexDirection: "row",
      textAlign: 'center',
      flexWrap: "wrap",
      justifyContent: 'center',
      flex: 1,
      marginLeft: "10%",
      marginRight: "10%"
    },
    
    container11: {
        marginTop: 10,

        flexDirection: "row",
        textAlign: 'center',
        flexWrap: false,
        flex: 1,
      },
    imagecontainer2: {
        width: 25,
        height: 25,
        marginRight: 4,
        textAlign: 'center',
        marginBottom: 5,
    },
    container2: {
      marginTop: 10,
      marginLeft: 30,
      minWidth: 130,
      flexDirection: "column",
      alignItems: "left",
      justifyContent:"flex-start",
      textAlign: 'left',
      flex: 1,
    },
    
    buttoncontainer: {
        marginRight: "30%",
        marginLeft: "30%",
        textAlign: 'center',
        marginTop: 5,
    },
    smallerbuttoncontainer: {
        marginRight: "35%",
        marginLeft: "35%",
        textAlign: 'center',
        marginTop: 5,
        marginBottom:20
    },
    imagecontainer: {
        width: 30,
        height: 30,
        textAlign: 'center',
        marginBottom: 2,
        flex:1,
    },
    rowcontainer2: {
        width: "100%",
        textAlign: 'center',
        flexDirection: "row",
        justifyContent: 'center',
        flex:1,
    },
    infobarcontainer: {
      textAlign: 'left',
      marginBottom: 5,
      flex:5,
    },
    statuscontainer: {
      textAlign: 'center',
      marginBottom: 0,
      marginLeft: 5,
      flex:1.2,
    },
    actionscontainer: {
      textAlign: 'center',
      marginBottom: 5,
      marginLeft: 5,
      flex:1.2,
      justifyContent: 'space-between',
    },
    rowcontainer: {width: "95%",
      textAlign: 'left',
      flexDirection: "row",
      marginBottom: 5,
      justifyContent: 'space-between',
      flex:1.2,
    },
  }

export default ForCostumer;

