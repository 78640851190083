import React from 'react';
import {Text,Image,View,CheckBox} from "react-native";
import "./Whyervesta.css";
import '../languages/i18n'; 
import {useTranslation} from 'react-i18next';

function Whyervesta() {
  const {t, i18n} = useTranslation();
  return (
 
          <View style={styles.rowcontainer3} >
            <Image source="/images/IMG_7903.jpg" style={styles.imagecontainer} />
            <ul style={styles.container2}>
                <view >
                <text className="form__logotextsmall">ERVESTA</text><text className="form__text4"> {t("isforyouif")}</text>
                </view>

                <ul style={styles.container1}>
                  <li>
                    <text className="form__text4" >{t("whyervestat1")}</text>
                  </li><br/>
                  <li>
                    <text className="form__text4">{t("whyervestat2")}</text>
                  </li><br/>
                  <li>
                    <text className="form__text4">{t("whyervestat3")}</text>
                  </li><br/>
                  <li>
                    <text className="form__text4">{t("whyervestat4")}</text>
                  </li><br/>
                  <li>
                    <text className="form__text4">{t("whyervestat5")}</text>
                  </li>
                </ul>
              </ul>
          </View>
            
  )}
  
const styles = {
   container1: {
     marginLeft: "10%",
     marginTop: "5%",
     flexDirection: "row",
     minwidth:"350px",
     textAlign: 'left',
     padding: "10px",
     flex: 1,
   },
   container2: {
     minWidth: "350px",
     width:"50%",
     display: "flex",
     flexDirection: "column",
      flexWrap: "wrap",
     textAlign: 'center',
     flex: 1,
   },
   
   container: {
     backgroundColor: "#BDBDBD86",
     marginTop: 5,
     width: "60%",
     flexDirection: "row",
     marginBottom: 5,
     justifyContent: 'center',
     flex: 1
   },
   buttoncontainer: {
       marginRight: "30%",
       marginLeft: "30%",
       textAlign: 'center',
       marginTop: 5,
   },
   smallerbuttoncontainer: {
       marginRight: "35%",
       marginLeft: "35%",
       textAlign: 'center',
       marginTop: 5,
       marginBottom:20
   },
   imagecontainer: {
       width: 500,
       height: 500,
       maxWidth: 500,
       textAlign: 'left',
       marginBottom: 15,
       marginTop: 15,
       marginLeft: 20,
       flex:1,
   },
   rowcontainer2: {
       width: "100%",
       textAlign: 'center',
       flexDirection: "row",
       justifyContent: 'center',
       flexwrap: "wrap",
       flex:1,
   },
   
   rowcontainer3: {
    backgroundColor: "#DFDFDF6C",
       marginTop: "5%",
       marginBottom: "5%",
       width: "auto",
       marginLeft: "5%",
       marginRight: "5%",
       textAlign: 'center',
       flexDirection: "row",
       justifyContent: 'center',
       display: "flex",
       padding: "50px",
       objectfit: "center",
       flexwrap: "wrap",
       alignItems: 'center',
   },
   rowcontainercolumn3: {
    marginTop: "5%",
    marginLeft: "5%",
    marginLeft: "5%",
    textAlign: 'center',
    flexDirection: "column",
    justifyContent: 'center',
    display: "flex",
    objectfit: "center",
    flexwrap: "wrap",
    alignItems: 'center',
    flex:1,
},
   clomncontainerwn: {
       textAlign: 'center',
       flexDirection: "column",
       justifyContent: 'center',
       flex:1,
   },

   centertext: {
       textAlign: 'center',
       flexDirection: "column",
       marginTop: "20px",
       fontSize: "2.8rem",
       justifyContent: 'center',
       flex:1,
       fontfamily:"Trebuchet MS"
       
   },
   
   centertext2: {
    textAlign: 'center',
    flexDirection: "column",
    marginTop: "20px",
    fontSize: "2rem",
    justifyContent: 'center',
    flex:1,
    fontfamily: 'Trebuchet MS',
    color: "#121212BE"


    
},
   centertextdesc: {
       textAlign: 'center',
       flexDirection: "column",
       marginTop: 2,
       justifyContent: 'center',
   },
  lefttext: {
    textAlign: 'left',
    flexDirection: "column",
    marginTop: 5,
    justifyContent: 'flex-start',
    fontfamily: "Inter",
    fontSize: "2rem",
    flex:1,
},
   infobarcontainer: {
     textAlign: 'left',
     marginBottom: 5,
     flex:5,
   },
   statuscontainer: {
     textAlign: 'center',
     marginBottom: 0,
     marginLeft: 5,
     flex:1.2,
   },
   actionscontainer: {
     textAlign: 'center',
     marginBottom: 5,
     marginLeft: 5,
     flex:1.2,
     justifyContent: 'space-between',
   },
   rowcontainer: {width: "95%",
     textAlign: 'left',
     flexDirection: "row",
     marginBottom: 5,
     justifyContent: 'space-between',
     flex:1.2,
   }
 }

  export default Whyervesta;