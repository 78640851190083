import React from 'react';
import {Text,Image,View,CheckBox} from "react-native";
import '../languages/i18n'; 
import {useTranslation} from 'react-i18next';

function Commissions() {
  const {t, i18n} = useTranslation();
  return (
 
    <>
             <View style={{marginLeft: "15%",marginRight:"15%",marginTop:"100px",marginBottom:"80px", textAlign:"center",flexDirection:"column",flex:1,display:"flex",justifyContent:"center"}}>
               <text className="form__shemeofworktext">{t("commissions")}</text></View>
            <View style={styles.rowcontainer3} >
                <View style={styles.clomncontainerwn} >
                    <text style={styles.righttext}>{t("commissionrate")}</text>
                    <br/>
                </View>
                <View  style={styles.clomncontainerwn}>
                    <text style={styles.centertext}>20 %</text>
                    <Text style={styles.centertextdesc}>{t("comratetext")}</Text>
                </View>
            </View>
            <View style={styles.rowcontainer3} >
                <View style={styles.clomncontainerwn} >
                    <text style={styles.righttext}>{t("commissionratemetal")}</text>
                    <br/>
                </View>
                <View  style={styles.clomncontainerwn}>
                    <text style={styles.centertext}>50 %</text>
                    <Text style={styles.centertextdesc}>{t("comratetext2")}</Text>
                </View>
            </View>
            <View style={styles.rowcontainer3} >
                <View style={styles.clomncontainerwn} >
                    <text style={styles.righttext}>{t("mincommission")}</text>
                    <br/>
                </View>
                <View  style={styles.clomncontainerwn}>
                    <text style={styles.centertext}>4500 KZT</text>
                    <Text style={styles.centertextdesc}>{t("mincommissiontext")}</Text>
                </View>
            </View>
            <View style={styles.rowcontainer3} >
                <View style={styles.clomncontainerwn} >
                    <text style={styles.righttext}>{t("specialcommusions")}</text>
                    <br/>
                </View>
                <View  style={styles.clomncontainerwn}>
                    <text style={styles.centertext}>info@ervesta.com</text>
                    <Text style={styles.centertextdesc}>{t("specialcommusionstext")}</Text>
                </View>
            </View>
            <View style={styles.rowcontainer3} >
                <View  style={styles.clomncontainerwn}>
                    <text style={styles.centertext}>{t("example1")}</text>
                    <text style={styles.centertext}>{t("productprice")} : 10000 KZT</text>
                    <Text style={styles.centertextdesc}>{t("example1text")}</Text>
                </View>
                <View  style={styles.clomncontainerwn}>
                    <text style={styles.centertext}>{t("example2")}</text>
                    <text style={styles.centertext}>{t("productprice")} : 22500 KZT</text>
                    <Text style={styles.centertextdesc}>{t("example2text")}</Text>
                </View>
                <View  style={styles.clomncontainerwn}>
                    <text style={styles.centertext}>{t("example3")}</text>
                    <text style={styles.centertext}>{t("productprice")} : 35000 KZT</text>
                    <Text style={styles.centertextdesc}>{t("example3text")}</Text>
                </View>
            </View>
             
    </>
  )}
  
const styles = {
  container1: {
    marginTop: 55,
    flexDirection: "row",
    textAlign: 'center',
    flex: 1,
  },
  container2: {
    marginTop: 10,
    flexDirection: "column",
    textAlign: 'center',
    flex: 1,
  },
  container: {
    marginTop: 5,
    flexDirection: "row",
    marginBottom: 5,
    flex: 1
  },
  buttoncontainer: {
      marginRight: "30%",
      marginLeft: "30%",
      textAlign: 'center',
      marginTop: 5,
      marginBottom:50
  },
  smallerbuttoncontainer: {
      marginRight: "35%",
      marginLeft: "35%",
      textAlign: 'center',
      marginTop: 5,
      marginBottom:20
  },
  imagecontainer: {
      width: 120,
      height: 120,
      textAlign: 'center',
      marginBottom: 5,
  },
  imagecontainer2: {
      width: 50,
      height: 50,
      textAlign: 'center',
      marginBottom: 5,
  },
  rowcontainer2: {
      width: "100%",
      textAlign: 'left',
      flexDirection: "row",
      justifyContent: 'center',
      flex:1,
  },
  rowcontainer3: {
      marginLeft: "15%",
      marginRight: "15%",
      marginBottom: 50,
      width: "70%",
      textAlign: 'center',
      flexDirection: "row",
      justifyContent: 'center',
      flex:1,
  },
  clomncontainerwn: {
      textAlign: 'top',
      padding:5,
      flexDirection: "column",
      flex:1,
  },
  centertext: {
      textAlign: 'center',
      padding: 5,
      backgroundColor:"#44444428",
      flexDirection: "column",
      marginTop: 5,
      justifyContent: 'center',
  },
  righttext: {
    textAlign: 'right',
    padding: 5,
    backgroundColor:"#7C7C7C12",
    flexDirection: "column",
    marginTop: 5,
    justifyContent: 'center',
},

lefttext: {
  textAlign: 'left',
  flexDirection: "column",
  marginTop: 5,
  justifyContent: 'center',
},
  centertextdesc: {
      textAlign: 'center',
      flexDirection: "column",
      marginTop: 2,
      justifyContent: 'center',
  },
  centertextdescred: {
      color: "red",
      textAlign: 'center',
      flexDirection: "column",
      marginTop: 2,
      justifyContent: 'center',
  },
  righttextdesc: {
    textAlign: 'right',
    flexDirection: "column",
    marginTop: 2,
    justifyContent: 'center',
},
righttextdescred: {
    color: "red",
    textAlign: 'right',
    flexDirection: "column",
    marginTop: 2,
    justifyContent: 'center',
},
  centertextdescgreen: {
      color: "green",
      textAlign: 'center',
      flexDirection: "column",
      marginTop: 2,
      justifyContent: 'center',
  },
  infobarcontainer: {
    textAlign: 'left',
    marginBottom: 5,
    flex:5,
  },
  statuscontainer: {
    textAlign: 'center',
    marginBottom: 0,
    marginLeft: 5,
    flex:1.2,
  },
  actionscontainer: {
    textAlign: 'center',
    marginBottom: 5,
    marginLeft: 5,
    flex:1.2,
    justifyContent: 'space-between',
  },
  rowcontainer: {
      width: "auto",
    textAlign: 'left',
    flexDirection: "row",
    marginBottom: 5,
    justifyContent: 'flex-start',
    flex:1.2,
  },
  a:{
      width: "33%",
  }
 }

  export default Commissions;