import React from 'react';
import {Text,Image,View,CheckBox} from "react-native";
import '../languages/i18n'; 
import {useTranslation} from 'react-i18next';

function StepsChart() {
  const {t, i18n} = useTranslation();
  return (
 
    <>
             <View style={{marginLeft: "15%",marginRight:"15%",marginTop:"100px",marginBottom:"80px", textAlign:"center",flexDirection:"column",flexWrap:"wrap", flex:1,display:"flex",justifyContent:"center"}}>
               <text className="form__shemeofworktext">{t("steps")}</text></View>
            <View style={styles.rowcontainer3} >
            <View style={styles.clomncontainerwn} >
               <View style={styles.rowcontainer2} >
                  <Image source="/images/number-1.png" style={styles.imagecontainer2} />
               </View>
                <text style={styles.centertext}>{t("waitingforpayment")}</text>
                <Text style={styles.centertextdesc}>{t("youshouldsend")}  4405 6397 0583 7399 - Halyk Bank - Zhazira Zhumabayeva</Text>
                <Text style={styles.centertextdescred}>{t("youshoulput")} {t("inthedescription")}</Text>
                <br/>
                <View style={styles.rowcontainer2} >
                  <Image source="/images/payment.png" style={styles.imagecontainer2} />
               </View>
               <text style={styles.centertext}><i class="fa-solid fa-clock"></i> {t("lessthan12hours")}</text>
             </View>
            <Image source="/images/right-arrow.png" style={{flex:0.2, marginRight:5, top:5,resizeMode: "contain",}} />
             <View  style={styles.clomncontainerwn}>
               <View style={styles.rowcontainer2} >
                  <Image source="/images/number-2.png" style={styles.imagecontainer2} />
               </View>
               <text style={styles.centertext}>{t("pvwfs")}</text>
                <Text style={styles.centertextdesc}>{t("afteryoumadepayment")}</Text>
                <br/>
                <View style={styles.rowcontainer2} >
                  <Image source="/images/supply.png" style={styles.imagecontainer2} />
               </View>
                <text style={styles.centertext}><i class="fa-solid fa-clock"></i> {t("23days")}</text>
             </View>
            <Image source="/images/right-arrow.png" style={{flex:0.2, top:5,resizeMode: "contain",}} />
             <View  style={styles.clomncontainerwn}>
               <View style={styles.rowcontainer2} >
                  <Image source="/images/number-3.png" style={styles.imagecontainer2} />
               </View>
                <text style={styles.centertext}>{t("productsuppliedandshipped")}</text>
                <Text style={styles.centertextdesc}>{t("erevstausesvar")}</Text>
                <br/>
                <View style={styles.rowcontainer2} >
                  <Image source="/images/shipped2.png" style={styles.imagecontainer2} />
               </View>
                <text style={styles.centertext}><i class="fa-solid fa-clock"></i> {t("10days")}</text>
             </View>
             <Image source="/images/right-arrow.png" style={{flex:0.2, top:5,resizeMode: "contain",}} />
             <View  style={styles.clomncontainerwn}>
               <View style={styles.rowcontainer2} >
                     <Image source="/images/number-4.png" style={styles.imagecontainer2} />
               </View>
                <text style={styles.centertext}>{t("productdelivered")}</text>
                <Text style={styles.centertextdesc}></Text>
                <br/>
                <View style={styles.rowcontainer2} >
                  <Image source="/images/delivered.png" style={styles.imagecontainer2} />
               </View>
             </View>
             
        </View>
    </>
  )}
  
const styles = {
  container1: {
    marginTop: 55,
    flexDirection: "row",
    textAlign: 'center',
    flex: 1,
  },
  container2: {
    marginTop: 10,
    flexDirection: "column",
    textAlign: 'center',
    flex: 1,
  },
  container: {
    marginTop: 5,
    flexDirection: "row",
    marginBottom: 5,
    flex: 1
  },
  buttoncontainer: {
      marginRight: "30%",
      marginLeft: "30%",
      textAlign: 'center',
      marginTop: 5,
      marginBottom:50
  },
  smallerbuttoncontainer: {
      marginRight: "35%",
      marginLeft: "35%",
      textAlign: 'center',
      marginTop: 5,
      marginBottom:20
  },
  imagecontainer: {
      width: 120,
      height: 120,
      textAlign: 'center',
      marginBottom: 5,
  },
  imagecontainer2: {
      width: 50,
      height: 50,
      textAlign: 'center',
      marginBottom: 5,
  },
  rowcontainer2: {
      width: "100%",
      textAlign: 'left',
      flexDirection: "row",
      justifyContent: 'center',
      flex:1,
  },
  rowcontainer3: {
      marginLeft: "10%",
      marginRight: "10%",
      marginBottom: 50,
      flexWrap: "wrap",
      width: "80%",
      textAlign: 'center',
      flexDirection: "row",
      justifyContent: 'center',
      flex:1,
  },
  clomncontainerwn: {
      textAlign: 'top',
      marginBottom: 20,
      minWidth: 135,
      flexDirection: "column",
      flex:1,
  },
  centertext: {
      textAlign: 'center',
      flexDirection: "column",
      marginTop: 5,
      justifyContent: 'center',
  },
  centertextdesc: {
      textAlign: 'center',
      flexDirection: "column",
      marginTop: 2,
      justifyContent: 'center',
  },
  centertextdescred: {
      color: "red",
      textAlign: 'center',
      flexDirection: "column",
      marginTop: 2,
      justifyContent: 'center',
  },
  centertextdescgreen: {
      color: "green",
      textAlign: 'center',
      flexDirection: "column",
      marginTop: 2,
      justifyContent: 'center',
  },
  infobarcontainer: {
    textAlign: 'left',
    marginBottom: 5,
    flex:5,
  },
  statuscontainer: {
    textAlign: 'center',
    marginBottom: 0,
    marginLeft: 5,
    flex:1.2,
  },
  actionscontainer: {
    textAlign: 'center',
    marginBottom: 5,
    marginLeft: 5,
    flex:1.2,
    justifyContent: 'space-between',
  },
  rowcontainer: {
      width: "auto",
    textAlign: 'left',
    flexDirection: "row",
    marginBottom: 5,
    justifyContent: 'flex-start',
    flex:1.2,
  },
  a:{
      width: "33%",
  }
 }

  export default StepsChart;