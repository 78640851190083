//import '../../App.css';
import { auth, db,user } from '../../firebase';
import './pagescss/signup.css';
import Footer from "../Footer";
import { Button } from "../Button";
import React, {useState} from 'react';
import { Link,withRouter  } from 'react-router-dom';
import MaskInput from 'react-maskinput';
import '../../languages/i18n'; 
import {useTranslation} from 'react-i18next';


function SignUp() {
  const {t, i18n} = useTranslation();
  const [uid, setuid] = useState("nouid");
  const changeuid = (text) => setuid(text);
  const [isdisabled, setDisable] = useState(false);
  auth.onAuthStateChanged(function(user) {
    if (user) {
      //changesignstatus(true);
    
      changeuid(user.uid+"")
      
    }else {
        //changesignstatus(false);
        changeuid("nouid");
    }
  });

  const resetPassword = () => {
    document.getElementById("forgotpassword").disabled = true;
    document.getElementById("forgotpassword").value = "sending...";
    var outputmsg = "";
    if(!validateemail(document.getElementById("emaillogin").value)){
      outputmsg = t("debug20")
      document.getElementById("emaillogin").style.border="2px solid red";
    } 
    if(outputmsg === "")
      {
        auth.sendPasswordResetEmail(document.getElementById("emaillogin").value)
        .then(() => {
          document.getElementById("forgotpassword").disabled = false;
          document.getElementById("forgotpassword").value = "succesfull...";
          alert(t("debug20"));
          // Password reset email sent!
          // ..
        })
        .catch((error) => {
          document.getElementById("forgotpassword").disabled = false;
          const errorCode = error.code;
          const errorMessage = error.message;
          document.getElementById("forgotpassword").value = t("forgotpass");
          
          alert("err1:" + errorCode + " " + errorMessage);
          // 
        });
      }
    else {
      document.getElementById("forgotpassword").disabled = false;
      alert(outputmsg);
      }
  }

  function getDate(){
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;
    return today;
}
function validateemail(text) {
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  if (reg.test(text) === false) {
    return false;
  }
  else {return true;
  }
}
  
    function validatesignupinputs() {
      var outputmsg = "";
      if(!validateemail(document.getElementById("emailRegister").value)){
        outputmsg = t("debug14")
        document.getElementById("emailRegister").style.border="2px solid red";
      }     
      if(document.getElementById("firstNameRegister").value.length < 2){
        outputmsg = outputmsg+t("debug16")
        document.getElementById("firstNameRegister").style.border="2px solid red";
      }    
      if(document.getElementById("lastNameRegister").value.length < 2){
        outputmsg = outputmsg+t("debug17")
        document.getElementById("lastNameRegister").style.border="2px solid red";
      }    
      if(document.getElementById("passwordRegister").value.length < 6){
        outputmsg = outputmsg+t("debug18")
        document.getElementById("passwordRegister").style.border="2px solid red";
      } 
      if(document.getElementById("passwordRegister").value+"" !== document.getElementById("confirmPassword").value+""){
        outputmsg = outputmsg+t("debug19")
        document.getElementById("confirmPassword").style.border="2px solid red"; 
      }
      if(parseInt(document.getElementById("phonenumberRegister").value.replaceAll("(","").replaceAll(")","").replaceAll("+","").replaceAll("-","").replaceAll(" ", ""))<70000000001 || document.getElementById("phonenumberRegister").value.length<10){
        outputmsg = outputmsg+"Phone number is invalid.\n"
        document.getElementById("phonenumberRegister").style.border="2px solid red";
      }
      if(outputmsg === "")
      return true;
      else {
        alert(outputmsg);
        setDisable(false);
        return false;}
    }
      function validatesignininputs() {
        var outputmsg = "";
        if(!validateemail(document.getElementById("emaillogin").value)){
          outputmsg = t("debug14")
          document.getElementById("emaillogin").style.border="2px solid red";
        }    
        if(document.getElementById("passwordlogin").value.length < 6){
          outputmsg = outputmsg+t("debug15")
          document.getElementById("passwordlogin").style.border="2px solid red";
        } 
        if(outputmsg === "")
        return true;
        else {
          alert(outputmsg);
          setDisable(false);
          return false;}
      }

    const registeruser = () =>{
      
      if(validatesignupinputs())
    {

          auth.createUserWithEmailAndPassword(document.getElementById("emailRegister").value+"", document.getElementById("passwordRegister").value+"")
                    .then((res) =>{
                      db.ref("/users/"+res.user.uid).set({
                        name: ""+document.getElementById("firstNameRegister").value,
                        surname: document.getElementById("lastNameRegister").value+"",
                        email: document.getElementById("emailRegister").value,
                        phone: document.getElementById("phonenumberRegister").value.replaceAll(/\D/g, ""),
                        registerdate: getDate(),
                          })
                          .then(() => {
                            window.location.href = "./";})
                    }
                    )
                    .catch(error => { alert(error.message) })   
      }
            
          
      };
      const signinuser = () =>{
        if(validatesignininputs()){
          auth.signInWithEmailAndPassword(document.getElementById("emaillogin").value+"", document.getElementById("passwordlogin").value+"")
                .then(() => {
                  
                  window.location.href = "./";
                })
                .catch(error => { setDisable(false);alert(error.message) })
        }
      };

          //Platform.OS === 'ios' ? 200 : 100
    return(
      <div className="topbody">
              <div style={{padding:"50px",textAlign:"center"}}>
         <text className="form__logotext" translate="no" to="/" >ERVESTA</text><br/>
        <text className="form__logotextslogan" translate="no" to="/"  >shop smart</text>
       </div>

      <text className="form__titellabel" for="firstName">{t("ifyoualready")}</text><br />
      <div className="form">
        <text className="form__titellabel" for="firstName">{t("signin")}</text><br />
          <div className="form-body">
              <div className="email">
                  
                  <input  type="email" id="emaillogin" className="form__input" placeholder={t("email")}/>
              </div>
              <div className="password">
                <input className="form__input" type="password"  id="passwordlogin" placeholder={t('password')}/>
              </div>
          </div>
          <div class="footer">
              <Button buttonStyle= "btn--outline2" onClick={signinuser}>{t("signin")}</Button>
          </div>
          <Link id="forgotpassword" className="form__label" to="" onClick={resetPassword} >{t("forgotpass")}</Link>
      </div>    

      <text className="form__titellabel" for="firstName">{t("ifyoudontaccount")}</text><br />
      
      <div className="form">
        <text className="form__titellabel" for="firstName">{t("signup")}</text><br />
          <div className="form-body">
              <div className="username">
                  <input className="form__input" type="text" id="firstNameRegister" placeholder={t('firstname')}/>
              </div>
              <div className="lastname">
                  <input  type="text" name="" id="lastNameRegister"  className="form__input"placeholder={t('lastname')}/>
              </div>
              <div className="">
              <MaskInput id="phonenumberRegister" className="form__input"  mask={'+7 (000) 000-00-00'}  maskChar="_" placeholder="+7 (000) 000-00-00" />
              </div>
              <div className="email">
                  <input  type="email" id="emailRegister" className="form__input" placeholder={t("email")}/>
              </div>
              <div className="password">
                  <input className="form__input" type="password"  id="passwordRegister" placeholder={t('password')}/>
              </div>
              <div className="confirm-password">
                  <input className="form__input" type="password" id="confirmPassword" placeholder={t('confirmpassword')}/>
              </div>
          </div>
          <div class="footer">
              <Button buttonStyle= "btn--outline2" onClick={registeruser} >{t("signup")}</Button>
          </div>
      </div>   
      <div style={{padding:"50px",textAlign:"center"}}>
        <text className="form__logotextslogan" translate="no" to="/"  >shop smart</text>
       </div>
      <Footer/>
      </div>  
      
    )       
}

export default SignUp;